<template>
	<div class="inquire-detail" @scroll="handleScroll">
		<ab-heads v-if="routeQuery.type == 'view'" :numbers="dataForm.inquiryNo" :createTime="dataForm.createTime"
			:customerNo="dataForm.customerNo"></ab-heads>
		<div class="top">
			<el-row :gutter="40">
				<el-col :span="8" v-if="addFlag">
					<div class="title" style="font-size: 20px; margin-bottom: 10px">BOM 导入</div>
					<div class="upload-demo">
						<el-upload :action="action" drag :headers="headers" :before-upload="beforeAvatarUpload"
							:on-success="uploadSuccess" :disabled="noCertifiedFlag" multiple :show-file-list="false">
							<div class="el-upload__text">
								<p>BOM 文件拖拽到这里</p>
								<el-button type="primary" :loading="isBtnLoading"
									:disabled="noCertifiedFlag">重新上传</el-button>
								<p>支持xls、xlsx、csv、文件不超过5M</p>
								<p @click.stop="downTempate">BOM 标准模板下载</p>
							</div>
						</el-upload>
					</div>
				</el-col>
				<el-col :span="8" v-if="addFlag">
					<div class="title" style="font-size: 20px; margin-bottom: 10px">BOM 报价概况</div>
					<div class="search-div">
						<div class="item">
							<span class="label">采购套数</span>
							<div class="seque">
								<div class="min btn" @click="addNumber('-')">-</div>
								<el-input type="number" @input="changeNumber" :disabled="!addFlag"
									v-model="dataForm.numberOfSets" @blur="numberBlur"></el-input>
								<div class="add btn" @click="addNumber('+')">+</div>
							</div>
						</div>
						<div class="item">
							<span class="label">总价格（含税）</span>
							<span class="value">￥{{ totalAccount }}</span>
						</div>
						<div class="item project-name">
							<span class="label">项目名称</span>
							<el-input :disabled="!addFlag" placeholder="请输入项目名称" v-model.trim="dataForm.customerNo"
								@blur="bindChanges"></el-input>
						</div>
					</div>
				</el-col>

				<el-col :span="!addFlag ? 24 : 8">
					<div class="analysis-overview">
						<div class="title">
							商品分析概况（型号数量共 {{ statusCount.count }} 个）
						</div>
						<div class="info-list">
							<div class="progress-bar">
								<ul class="ul-bar clearfix" style="margin-left: 0">
									<li v-for="(value, key) in resultData" :key="key" :style="{
										width: `calc(${(statusCount[key] / statusCount.count) * 100 + '%'}${liWidth(key)})`,
										background: resultData[key].color, 'margin-left': marginLeft(key)
									}">
										<span class="number" v-if="(statusCount[key] / statusCount.count) * 100">{{
											statusCount[key] }}</span>
									</li>
								</ul>
							</div>
							<el-row>
								<el-col style="flex: 0 0 20%" v-for="(value, key) in resultData" :key="key">
									<i class="quanquan" :style="{ 'background-color': resultData[key].color }"></i>
									{{ resultData[key].label }}
								</el-col>
							</el-row>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="good-info">
			<div class="heads" ref="heads">
				<ul>
					<li :class="{ isActive: activeName == 'all' }" @click="activeName = 'all'; selectTable()">
						全部 ({{ statusCount.count }})
					</li>
					<li v-for="(value, key) in resultData" :key="key" :class="{ isActive: activeName == key }"
						@click="tabChange(key)">
						{{ resultData[key].label }}({{ statusCount[key] }})
					</li>
				</ul>
				<el-button @click="exportFile" style="margin-top: 18px" :loading="exportLoading"
					class="pub-btn">导出报价单</el-button>
			</div>
			<el-form :model="dataForm" ref="dataForm" label-position="top" class="dataForm" :rules="rules"
				scroll-to-error>
				<vxe-table border ref="tableRef" height="900" :class="{ 'fixed-header': isHeaderFixed }"
					:row-class-name="getRowClass" :span-method="arraySpanMethod" :data="tabClick" :loading="isloading"
					:column-config="{ resizable: true, useKey: true }" :sync-resize="true"
					:row-config="{ keyField: 'bomData.rowIndex', isHover: false, height: 145 }"
					:scroll-x="{ enabled: true, gt: 50, rSize: 50, oSize: 10 }"
					:scroll-y="{ enabled: true, model: 'wheel', gt: 50, rSize: 50, oSize: 10, adaptive: true }"
					keep-source show-overflow show-header-overflow
					:checkbox-config="{ checkField: 'checked', checkMethod: selectable, reserve: true }"
					@checkbox-all="selectAllChangeEvent" @checkbox-change="selectChangeEvent">
					<vxe-column type="checkbox" width="36"></vxe-column>
					<vxe-column type="rowIndex" title="序号" width="56" align="center">
						<template #default="{ row }">
							{{ row.bomData.rowIndex }}
						</template>
					</vxe-column>
					<vxe-column field="content" title="原始需求" min-width="200" header-align="center">
						<template #default="{ row }">
							<div style="word-wrap: break-word; word-break: break-word;">
								{{ row.bomData.rowData }}
							</div>
							<p :title="row.bomData.materialNo">
								<span class="gray-color">编号：</span>
								<span>{{ row.bomData.materialNo || '' }}</span>
							</p>
						</template>
					</vxe-column>
					<vxe-column field="collateNeed" title="整理后的需求" min-width="230" header-align="center">
						<template #default="{ row }">
							<p :title="row.bomData.productModel">
								<span class="gray-color">型号：</span>
								<span>{{ row.bomData.productModel || '' }}</span>
							</p>
							<p :title="row.bomData.productType">
								<span class="gray-color">品类：</span>
								<span>{{ row.bomData.productType || '' }}</span>
							</p>
							<p :title="row.bomData.brand">
								<span class="gray-color">品牌：</span>
								<span>{{ row.bomData.brand || '' }}</span>
							</p>
							<p :title="row.bomData.productPackaging">
								<span class="gray-color">封装：</span>
								<span>{{ row.bomData.productPackaging || '' }}</span>
							</p>
							<p :title="row.bomData.parameter">
								<span class="gray-color">参数：</span>
								<span>{{ row.bomData.parameter || '' }}</span>
							</p>
							<div class="col-icon">
								<el-icon @click="editClick(row)">
									<EditPen />
								</el-icon>
								<el-icon class="del-icon" @click="delClick([row.bomData.inquiryOrderDetailId])">
									<DeleteFilled />
								</el-icon>
							</div>
						</template>
					</vxe-column>
					<vxe-column field="aloneNum" title="单用量" min-width="130" align="center">
						<template #default="{ row }">
							<el-input placeholder="请输入" :disabled="!addFlag || row.matchResult.matchStatus == 3"
								v-model="row.bomData.aloneNum" type="number" @input="aloneChange(row)"
								@blur="calcNumber(row)"></el-input>
						</template>
					</vxe-column>
					<vxe-column field="usageNum" title="购买量" min-width="130" align="center">
						<template #default="{ row }">
							<div class="usage-num">
								<span v-if="row.matchResult.matchStatus !== 2">
									<el-input placeholder="请输入" v-model="row.bomData.usageNum" type="number"
										@input="usageNumChange(row)"
										@blur="validateIncrement(row, row.bomData.usageNum)"></el-input>
								</span>
								<span v-else>
									<el-input placeholder="请输入" :value="(row.bomData.usageNum = 1)"
										type="number"></el-input>
								</span>
								<span v-if="isStockWarning(row)" class="stock-tip">库存不足</span>
							</div>
						</template>
					</vxe-column>
					<vxe-column field="result" title="结果" width="55" align="center" header-align="center">
						<template #default="{ row }">
							<div class="result-item">
								<el-popover placement="right" trigger="hover" :content="row.matchResult.matchStatus === 0 ? '请确认' :
									row.matchResult.matchStatus === 1 ?
										(row.bomData.matchMethod === 'artificial' ? '人工匹配' : '系统匹配') :
										'无法匹配'">
									<template #reference>
										<el-icon
											v-if="row.matchResult.matchStatus === 0 && (!row.bomData.matchMethod || row.bomData.matchMethod === 'system')"
											class="dot" :class="resultData[row.matchResult.matchStatus].class">
											<WarningFilled />
										</el-icon>
										<el-icon v-if="row.matchResult.matchStatus === 1" class="dot"
											:class="!row.bomData.matchMethod || row.bomData.matchMethod === 'system' ? 'check' : 'confirmed'">
											<SuccessFilled />
										</el-icon>
										<el-icon v-if="row.matchResult.matchStatus === 2" class="dot"
											:class="resultData[row.matchResult.matchStatus].class">
											<CircleCloseFilled />
										</el-icon>
									</template>
								</el-popover>
							</div>
						</template>
					</vxe-column>
					<vxe-column field="recommend" title="推荐商品" min-width="305" header-align="center">
						<template #default="{ row }">
							<div v-if="(row.matchResult.matchStatus === 1 || row.matchResult.matchStatus === 0) && row.matchResult.selectedModel?.mpn"
								class="col-item">
								<div class="col-item">
									<template v-if="row.matchResult">
										<p>
											<span class="gray-color">型号：</span>
											<span
												v-if="row.bomData.matchMethod === 'system' && row.matchResult.selectedModel.highlight && row.matchResult.selectedModel.highlight.mpn"
												class="high-light" v-html="row.matchResult.selectedModel.highlight.mpn">
											</span>
											<span v-else>{{ row.matchResult.selectedModel.mpn }}</span>
										</p>
										<p>
											<span class="gray-color">品类：</span>
											<span
												v-if="row.bomData.matchMethod === 'system' && row.matchResult.selectedModel.highlight && row.matchResult.selectedModel.highlight.sub_category"
												class="high-light"
												v-html="row.matchResult.selectedModel.highlight.sub_category">
											</span>
											<span v-else>{{ row.matchResult.selectedModel.categoryName }}</span>
										</p>
										<p>
											<span class="gray-color">品牌：</span>
											<span
												v-if="row.bomData.matchMethod === 'system' && row.matchResult.selectedModel.highlight && row.matchResult.selectedModel.highlight.brand"
												class="high-light"
												v-html="row.matchResult.selectedModel.highlight.brand"></span>
											<span v-else>{{ row.matchResult.selectedModel.brand }}</span>
										</p>
										<p>
											<span class="gray-color">封装：</span>
											<span
												v-if="row.bomData.matchMethod === 'system' && row.matchResult.selectedModel.highlight && row.matchResult.selectedModel.highlight.package"
												class="high-light"
												v-html="row.matchResult.selectedModel.highlight.package"></span>
											<span v-else>{{ row.matchResult.selectedModel.productPackaging }}</span>
										</p>
										<p class="description-text-box">
											<span class="gray-color">描述：</span>
											<span
												v-if="row.bomData.matchMethod === 'system' && row.matchResult.selectedModel.highlight && row.matchResult.selectedModel.highlight.description"
												class="high-light break-word"
												v-html="row.matchResult.selectedModel.highlight.description">
											</span>
											<span class="break-word" v-else>{{ row.matchResult.selectedModel.parameter }}</span>
										</p>
									</template>
								</div>
							</div>
							<div class="tishi" v-else>
								<el-icon>
									<WarningFilled />
								</el-icon>暂无匹配项
							</div>
						</template>
					</vxe-column>
					<vxe-column field="productMoQ" title="起订量/交期" min-width="200" header-align="center">
						<template #default="{ row }">
							<div class="col-item"
								v-if="(row.matchResult.matchStatus === 1 || row.matchResult.matchStatus === 0) && row.matchResult.selectedModel.priceData?.price">
								<p>小包装：{{ row.matchResult.selectedModel?.priceData &&
									row.matchResult.selectedModel?.priceData.package }}</p>
								<p>起订量：{{ row.matchResult.selectedModel?.priceData &&
									row.matchResult.selectedModel?.priceData.min_buy_num }}</p>
								<p>增量：{{ row.matchResult.selectedModel?.priceData &&
									row.matchResult.selectedModel?.priceData.min_increment }}</p>
								<p>交期：{{ row.matchResult.selectedModel?.priceData &&
									row.matchResult.selectedModel?.priceData.delivery_time }}</p>
								<p>库存：{{ row.matchResult.selectedModel?.priceData &&
									row.matchResult.selectedModel?.priceData.stock }}</p>
							</div>
							<p v-else-if="isPriceLoading">价格加载中....</p>
							<div class="tishi" v-else>
								<el-icon>
									<WarningFilled />
								</el-icon>暂无报价，无法下单
							</div>
						</template>
					</vxe-column>
					<vxe-column field="price" title="单价(含税)" min-width="150" align="center" header-align="center">
						<template #default="{ row }">
							<div class="col-item">
								<p :title="unitPriceCount(row)" v-if="unitPriceCount(row) > 0">
									{{ unitPriceCount(row) }}
								</p>
							</div>
							<div class="col-icon">
								<el-popover placement="bottom" title="价格梯度" :width="200" trigger="hover">
									<template #reference>
										<p v-if="row.matchResult.selectedModel?.priceData && row.matchResult.selectedModel?.priceData.price_list &&
											dataForm.status != 2 && dataForm.status != 1"><el-icon>
												<View />
											</el-icon>
										</p>
									</template>
									<template #default
										v-if="row.matchResult.selectedModel?.priceData && row.matchResult.selectedModel?.priceData.price_list">
										<p v-for="(item, index) in row.matchResult.selectedModel?.priceData.price_list"
											:key="item">
											<span>{{ index }}+：</span><span>￥{{ item }}</span>
										</p>
									</template>
								</el-popover>
							</div>
						</template>
					</vxe-column>
					<vxe-column field="total" title="小计(含税)" width="120" align="center">
						<template #default="{ row }">
							<div class="col-item">{{ subtotal(row) }}</div>
						</template>
					</vxe-column>
					<vxe-column field="operator" title="操作" width="100" align="center" fixed="right">
						<template #default="{ row }">
							<div class="btn-group">
								<vxe-button status="success"
									@click="changeModel(row.matchResult.relatedModels, row)">更换型号</vxe-button>
								<vxe-button status="warning" :disabled="row.matchResult.matchStatus === 2"
									@click="changeUpdate(row, 'confirm')">确认型号</vxe-button>
								<vxe-button status="danger" @click="changeUpdate(row, 'noMatch')">无法匹配</vxe-button>
							</div>
						</template>
					</vxe-column>
				</vxe-table>
				<div class="tfoot"
					v-if="this.dataForm.status != 1 && this.dataForm.status != 2 && this.dataForm.status !== '0'">
					<div>
						<div class="iconBtn" @click="addRowClick">
							<el-icon style="color: #00458e">
								<CirclePlus />
							</el-icon>新增行
						</div>
						<div class="iconBtn" @click="delRowClick()">
							<el-icon style="color: #ff0000">
								<CircleClose />
							</el-icon>删除行
						</div>
					</div>
					<div class="total">
						<span class="title">总金额（含税）：</span>
						<span class="price">￥{{ totalAccount }}</span>
					</div>
				</div>
			</el-form>
		</div>
		<div class="footers">
			<el-button v-if="addFlag" :loading="btnLoading" @click="back">返回</el-button>
			<el-button type="primary" @click="orderConfig">一键下单</el-button>
		</div>
		<change-model-dialog v-model:visible="showVisible" :dataFrom="changeData" @cancel="showVisible = false"
			@confirm="changeConfirm"></change-model-dialog>
		<update-dialog v-model:visible="updateVisible" :data="updateData" @cancel="updateVisible = false"
			@confirm="updateConfirm"></update-dialog>
		<add-dialog v-model:visible="addVisible" @confirm="addConfirm" :btnLoading="btnLoading"
			@cancel="addVisible = false" :numberOfSets="dataForm.numberOfSets"></add-dialog>
		<ab-analy-dialog :btnLoading="btnLoading" @confirm="analyConfirm" v-model:visible="analyVisible"
			:tableData="tableList" :inquiryOrderNo="dataForm.inquiryOrderNo"
			@cancel="analyVisible = false"></ab-analy-dialog>
	</div>
</template>

<script>
import _ from 'lodash'
import changeModelDialog from './box/change-model-dialog';
import updateDialog from './box/update-dialog';
import addDialog from './box/add-dialog';
import vueCookie from 'vue-cookies';
import formatNum from '@/utils/format-num';
import { download } from '@/utils/download';

export default {
	name: 'inquire-detail',
	components: {
		changeModelDialog,
		updateDialog,
		addDialog,
	},
	data() {
		return {
			tableList: [],
			analyVisible: false,
			changeFlag: false,
			activeName: 'all',
			isloading: false,
			updateData: {},
			changeData: {},
			updateNumberData: {},
			selected: [],
			action: this.$adornUrl('/inquiryBase/bomAnalysis'),
			headers: { Authorization: vueCookie.get('token') },
			rules: {},
			dataForm: {
				entry: [
					{
						model: '3333',
					},
				],
				numberOfSets: 1,
			},
			// 	匹配状态0待确认，1完全匹配，2无匹配
			resultData: {
				0: { label: '待确认', class: 'confirmed', color: '#FF8400' },
				1: { label: '完全匹配', class: 'check', color: '#B58623' },
				2: { label: '无匹配', class: 'nomatch', color: '#FF0000' },
			},
			routeQuery: {},
			showVisible: false,
			updateVisible: false,
			addVisible: false,
			updateNumberVisible: false,
			tableData: [],
			btnLoading: false,
			isBtnLoading: false,
			customerNo: '',
			exportLoading: false,
			isPriceLoading: false,
			usageNum: 1,
			isConfirmed: false,
			totalAccount: null,
			isHeaderFixed: false, // 是否固定表头
		};
	},
	computed: {
		sidebarFold: {
			get() {
				return this.$store.state.sidebarFold;
			},
			set(val) {
				this.$store.commit('updateSidebarFold', val);
			},
		},
		mainTabs: {
			get() {
				return this.$store.state.mainTabs;
			},
			set(val) {
				this.$store.commit('updateMainTabs', val);
			},
		},
		mainTabsActiveName: {
			get() {
				return this.$store.state.mainTabsActiveName;
			},
			set(val) {
				this.$store.commit('updateMainTabsActiveName', val);
			},
		},
		statusCount() {
			let total = {
				0: 0,
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				count: this.tableData.length,
			};
			if (this.tableData && this.tableData.length > 0) {
				this.tableData.map((item) => {
					let status = parseInt(item?.matchResult.matchStatus || 0);
					total[status] += 1;
				});
			}
			return total;
		},
		// 切换tab
		tabClick() {
			if (this.activeName != 'all') {
				return this.tableData.filter(
					(item) => item.matchResult.matchStatus == this.activeName
				);
			} else {
				return this.tableData;
			}
		},
		marginLeft() {
			return function (key) {
				let text = '0px';
				if (key != 0) {
					let flag = 0;
					for (let index = 0; index < key; index++) {
						if (this.statusCount[index]) {
							flag++;
						}
					}
					if (flag > 0) {
						text = '-16px';
					}
				}
				return text;
			};
		},
		liWidth() {
			return function (key) {
				let text = '';
				if (key != 0) {
					let flag = 0;
					for (let index = 0; index < key; index++) {
						if (this.statusCount[index]) {
							flag++;
						}
					}
					if (flag && this.statusCount[key]) {
						text = ` + 16px`;
					}
				}
				return text;
			};
		},
		orderDisabled() {
			let idList = [];
			this.selected.map((item) => {
				// 完全匹配并且有价格才能下单
				if (item.matchResult.matchStatus == 1 && this.unitPriceCount(item) > 0) {
					idList.push(item.id);
				}
			});
			return !idList.length;
		},
		price() {
			let num = 0;
			if (this.countTotal) {
				num = this.$calc
					.div(this.countTotal, this.dataForm.numberOfSets)
					.toFixed(4);
			}
			return num;
		},
		addFlag() {
			return this.routeQuery.type !== 'view';
		},
		noCertifiedFlag() {
			return !JSON.parse(window.localStorage.getItem('companyId'));
		},
	},
	watch: {
		$route() {
			let query = this.$route.query;
			if (query.detailFlag) {
				this.routeQuery = query;
			}
		},
	},
	activated() {
		let query = this.$route.query || {};
		this.routeQuery = Object.keys(query).length ? query : this.routeQuery;
		if (typeof this.routeQuery.cache === 'string' ? JSON.parse(this.routeQuery.cache) : this.routeQuery.cache) {
			return false;
		} else {
			// 确保刷新之后，是当前的订单，否则刷新之后，会变成上一个订单的详情
			this.dataForm.inquiryOrderNo = this.routeQuery.inquiryOrderNo;
			this.routeQuery.cache = true;
		}
		if (this.routeQuery.type == 'add') {
			this.tableData = [];
		} else {
			// 如有缓存数据，则获取并立即删除sessionStorage数据
			const inquiryData = this.$utils.getSessionStorage('inquiryData');
			if (inquiryData) {
				this.setData(inquiryData);
				this.$utils.removeSessionStorage('inquiryData');
				// 添加滚动到标签页
				this.$nextTick(() => {
					this.scrollToTabs();
				});
				return;
			}
			// 如果没有缓存数据,则请求接口
			this.getDetailData(this.dataForm.inquiryOrderNo || this.routeQuery.inquiryOrderNo);
			if (this.routeQuery.type !== 'copy') {
				this.dataForm.id = this.routeQuery.id;
			}
			// 获取总金额
			this.countTotal();
			this.$nextTick(() => {
				this.scrollToTabs();
			});
		}
	},
	mounted() {
		// 计算表格高度
		this.calcTableHeight();
		// 监听窗口大小变化
		window.addEventListener('resize', this.calcTableHeight);
		// 滚动效果
		this.$nextTick(() => {
			this.scrollToTabs();
		});
		// 监听滚动
		window.addEventListener('scroll', this.handleScroll, true);
		this.routeQuery = this.$route.query;
		if (this.routeQuery.type == 'add') {
			this.tableData = [];
		} else {
			// 如有缓存数据，则获取并立即删除sessionStorage数据
			const inquiryData = this.$utils.getSessionStorage('inquiryData');
			if (inquiryData) {
				this.setData(inquiryData);
				this.$utils.removeSessionStorage('inquiryData');
				return;
			}

			// 如果没有缓存数据,则请求接口
			this.getDetailData(this.dataForm.inquiryOrderNo || this.routeQuery.inquiryOrderNo);
			if (this.routeQuery.type !== 'copy') {
				this.dataForm.inquiryOrderNo = this.routeQuery.inquiryOrderNo;
			}
			// 获取总金额
			this.countTotal();
		}
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.calcTableHeight);
		window.removeEventListener('scroll', this.handleScroll, true);
	},
	methods: {
		// 计算表格高度
		calcTableHeight() {
			this.$nextTick(() => {
				const windowHeight = window.innerHeight;
				const tableTop = this.$refs.tableRef?.$el.getBoundingClientRect().top || 0;
				const footerHeight = 100;
				this.tableHeight = windowHeight - tableTop - footerHeight;
			});
		},
		// 添加滚动到标签页
		scrollToTabs() {
			setTimeout(() => {
				const tabsElement = document.querySelector('.heads');
				if (tabsElement) {
					tabsElement.scrollIntoView({
						behavior: 'smooth',
						block: 'start'
					});
				}
			}, 100); // 添加小延时确保DOM已完全渲染
		},
		// 监听滚动
		handleScroll() {
			// 获取标签页元素
			const headsElement = this.$refs.heads;
			if (!headsElement) return;

			// 获取标签页的位置信息
			const headsRect = headsElement.getBoundingClientRect();

			// 判断标签页是否在可视区域内
			// 当标签页顶部超出视口顶部时(headsRect.top <= 0)或整个标签页超出可视区域时(headsRect.bottom <= 0)
			this.isHeaderFixed = headsRect.top <= 0 || headsRect.bottom <= 0;
		},
		// 切换标签页
		tabChange(key) {
			// 先显示loading
			this.isloading = true;

			requestAnimationFrame(() => {
				this.activeName = key;
				this.selectTable();
				this.isloading = false;
			});
		},
		// 下载模板
		downTempate() {
			window.open(
				'https://scp-static.oss-cn-shenzhen.aliyuncs.com/prod/template/bom/BOM表模板.xlsx'
			);
		},
		// 导出文件
		exportFile() {
			this.exportLoading = true;

			this.$get(`/inquiry-orders/${this.dataForm.inquiryOrderNo}/details/export`, undefined, 'api')
				.then(({ data }) => {
					try {
						const { content, filename } = data.result;
						download(content, filename);
					} catch (err) {
						this.$message.error('导出失败');
					}
				})
				.finally(() => {
					this.exportLoading = false;
				});
		},
		// 通知后端更新采购量参数
		async updateUsageNum(item) {
			// 询价单编号
			const inquiryOrderNo = this.dataForm.inquiryOrderNo;
			let inquiryDetails = [];

			if (item) {
				inquiryDetails.push({
					inquiryOrderDetailId: item.bomData.inquiryOrderDetailId,
					usageNum: item.bomData.usageNum,
					aloneNum: item.bomData.aloneNum
				});
			} else {
				this.tableData.forEach((item, index, self) => {
					inquiryDetails.push({
						inquiryOrderDetailId: self[index].bomData.inquiryOrderDetailId,
						usageNum: self[index].bomData.usageNum,
						aloneNum: self[index].bomData.aloneNum
					});
				});
			}

			// 通知后台更新采购数量的参数
			const params = { inquiryDetails: inquiryDetails, numberOfSets: this.dataForm.numberOfSets };

			this.$put(`/inquiry-orders/${inquiryOrderNo}/details/batch-update-purchase-quantity`, params, 'api');
		},
		// 计算采购套数
		changeNumber() {
			this.dataForm.numberOfSets = this.dataForm.numberOfSets.replaceAll('.', '');
			// 计算采购量
			this.calcNumber();
			this.changeFlag = true;
			// 获取总金额
			this.countTotal()
			// 通知后端更改采购套数
			this.updateUsageNum()
		},
		// 采购套数输入框失去焦点
		numberBlur(event) {
			const value = parseFloat(event.target.value);
			// 输入为非数字，则默认为1
			if (!value) {
				this.dataForm.numberOfSets = 1;
			}
			this.calcNumber();
			this.changeFlag = true;
		},
		// 增加或减少采购套数
		addNumber(type) {
			if (this.routeQuery.type === 'view') {
				return;
			}
			if (type == '-') {
				if (this.dataForm.numberOfSets > 1) {
					this.dataForm.numberOfSets--;
				} else {
					return;
				}
			} else {
				this.dataForm.numberOfSets++;
			}
			// 计算采购量
			this.calcNumber();
			// 获取总金额
			this.countTotal()
			// 通知后端更改采购套数
			this.updateUsageNum()
			this.changeFlag = true;
		},
		// 单用量输入框
		aloneChange(row) {
			row.bomData.aloneNum = row.bomData.aloneNum.replaceAll('.', '');
			// 通知后端更改采购套数
			this.updateUsageNum(row)
			// 获取总金额
			this.countTotal()
		},
		// 购买量输入框
		usageNumChange(row) {
			row.bomData.usageNum = row.bomData.usageNum.replaceAll('.', '');
			// 获取总金额
			this.countTotal()
			// 通知后端更改采购套数
			this.updateUsageNum(row)

		},
		// 获取行样式
		getRowClass({ row }) {
			return {
				'stock-warning': row.bomData?.usageNum > row.matchResult?.selectedModel?.priceData?.stock
			}
		},
		// 判断是否显示库存警告
		isStockWarning(row) {
			return row.bomData?.usageNum > row.matchResult?.selectedModel?.priceData?.stock;
		},
		// 验证购买量是否是增量的倍数
		validateIncrement(row, originalValue) {
			const minIncrement = row.matchResult.selectedModel?.priceData?.min_increment;
			const stock = row.matchResult.selectedModel?.priceData?.stock;
			// 购买数量必须是增量的倍数
			if (minIncrement && row.bomData.usageNum % minIncrement !== 0) {
				this.$message.error(`购买数量必须是增量 ${minIncrement} 的倍数`);
				row.bomData.usageNum = originalValue;
				this.aloneBlur(row);
				this.updateUsageNum(row);
				return;
			}
			// 购买量大于库存
			if (stock && row.bomData.usageNum > stock) {
				this.isStockWarning(row);
				this.$message.warning(`购买数量已超出库存(${stock})`);
				this.aloneBlur(row);
				this.updateUsageNum(row);
				return;
			}

			// 通知后端更改购买量
			this.updateUsageNum(row)
		},
		/**
		 * 购买量:usageNum
		 * 起订量:min_buy_num
		 * 增量：min_increment
		 *
		 * 当购买量等于起订量时，则购买数量=起订量；
		 * 当购买量小于起订量时，则购买数量=起订量；
		 * 当购买量大于起订量时，则购买数量=单用量，若是超出的部分，没有满足"增量"的倍数，直接往上取整倍
		 * @param row 行数据
		 */
		aloneBlur(row) {
			let num = 0;
			if (!row.matchResult.selectedModel) return;

			const usageNum = row.bomData.usageNum;
			const minBuyNum = row.matchResult.selectedModel.priceData?.min_buy_num;
			const minIncrement = row.matchResult.selectedModel.priceData?.min_increment;

			if (usageNum > minBuyNum) {
				num = this.$calc.add(minBuyNum, Math.ceil((usageNum - minBuyNum) / minIncrement) * minIncrement);
			} else if (usageNum < minBuyNum) {
				num = minBuyNum;
			} else {
				num = usageNum;
			}

			row.bomData.usageNum = num;
		},
		/**
		 * 计算购买量逻辑：购买量 = 单用量 * 采购套数
		 */
		calcNumber(row) {
			this.tableData.forEach((e) => {
				const calcNum = this.$calc.mul(e.bomData.aloneNum, this.dataForm.numberOfSets);
				// 如果购买量大于计算量，则不进行计算
				if (e.bomData.usageNum > calcNum) {
					return;
				} else {
					e.bomData.usageNum = calcNum;
				}
				this.aloneBlur(e);
			});
			// 通知后端更改购买量
			this.updateUsageNum(row)
		},
		// 更改型号
		changeModel(data, row) {
			this.changeData.data = data;
			this.changeData.row = row;
			this.changeData.rowId = row.bomData.inquiryOrderDetailId;
			this.changeData.demandNum = row.bomData.demandNum;
			this.changeData.usageNum = row.bomData.usageNum;
			this.showVisible = true;
		},
		beforeAvatarUpload(file) {
			const isLtSize = file.size / 1024 / 1024 < 5;
			if (!isLtSize) {
				this.$message({
					type: 'error',
					message: file.name + '不能超过5MB!',
					offset: 380,
					grouping: true,
				});
				return false;
			}
			let types = ['xlsx', 'xls'];
			var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
			if (types.indexOf(testmsg.toLowerCase()) === -1) {
				this.$message({
					message: '仅支持 xlsx 和 xls 文件',
					type: 'warning',
				});
				return false;
			}
			this.isBtnLoading = true;
			this.customerNo = file.name ? file.name.split('.')[0] : '';
		},
		uploadSuccess(res) {
			if (res.code === 'C0000') {
				this.tableList = res.result || [];
				this.analyVisible = true;
			} else {
				this.$message.error(res.message);
			}
			this.isBtnLoading = false;
		},
		// 单价
		unitPriceCount(row) {
			// 添加防御性检查
			if (!row?.matchResult?.selectedModel?.priceData?.price_list) {
				return 0;
			}
			const selectedModel = row.matchResult.selectedModel;
			const priceTierIds = selectedModel.priceData.price_list;
			const usageNum = row.bomData?.usageNum;
			try {
				// 价格梯度降序排序
				const sortedEntries = Object.entries(priceTierIds)
					.sort((a, b) => b[0] - a[0]);
				// 遍历价格梯度，找到匹配的价格
				for (const [quantity, price] of sortedEntries) {
					if (usageNum >= parseInt(quantity, 10)) {
						return price;
					}
				}

				// 如果没有找到匹配的价格，返回最低档位价格
				return sortedEntries[sortedEntries.length - 1]?.[1] || 0;
			} catch (error) {
				console.error('计算单价失败:', error);
				return 0;
			}
		},
		// 加载页面数据
		getDetailData(inquiryOrderNo) {
			this.isloading = true;
			let url = `/inquiry-orders/${inquiryOrderNo}`;

			this.$get(url, undefined, 'api')
				.then(({ data }) => {
					if (data.code === 'C0000') {
						let originData = data.result;

						// 检查originData和originData.inquiryDetails是否存在
						if (originData && originData.inquiryDetails) {
							// 筛选出状态为完全匹配或待确认的数据
							const matchStateObj = {
								...data.result,
								inquiryDetails: originData.inquiryDetails.filter((item) => item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0),
							};

							// 价格接口参数
							const paramsPrice = {
								queryList: matchStateObj.inquiryDetails.map((o) => {
									return {
										mpn: o.bomData.selectedModelMpn,
										quantity: o.bomData.usageNum || '',
									};
								}),
							};
							// 先渲染基础数据
							this.setData(originData);
							this.isloading = false;
							this.isPriceLoading = true;

							// 请求价格接口
							this.$post('/products/price', paramsPrice, 'api')
								.then(({ data }) => {
									if (data.code === 'C0000') {
										if (data.result) {
											const priceData = data.result;

											// 匹配结果中匹配状态为完全匹配或待确认的，将价格数据赋值给 matchResult.selectedModel.priceData，并与原本数据进行筛选
											originData.inquiryDetails.forEach((item) => {
												if (item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0) {
													item.matchResult.selectedModel = {
														...item.matchResult.selectedModel,
														priceData: priceData.find(
															(o) => o.mpn === item.bomData.selectedModelMpn
														),
													};
												}
											});
											// 价格数据合并后，重新渲染页面数据
											this.setData(originData);
											// 获取总金额
											this.countTotal()
											// 通知后端更改采购套数
											this.updateUsageNum()
										} else {
											this.$message.error(data.message);
										}
									}
								})
								.finally(() => {
									this.isPriceLoading = false;
								});
						}
					}
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		// 合并列
		arraySpanMethod({ row, columnIndex }) {
			if (row.matchResult.matchStatus === 2 || !row.matchResult.selectedModel) {
				// 无匹配
				if (columnIndex == 7) {
					return {
						rowspan: 1,
						colspan: 4,
					};
				} else if (columnIndex > 7 && columnIndex < 11) {
					return {
						rowspan: 0,
						colspan: 0,
					};
				}
			} else if ((row.matchResult.matchStatus === 0 || row.matchResult.matchStatus === 1) && row.matchResult.selectedModel?.mpn && !row.matchResult.selectedModel.priceData?.price) {
				// 完全匹配or待确认 且有型号和无价格
				if (columnIndex == 8) {
					return {
						rowspan: 1,
						colspan: 3,
					};
				} else if (columnIndex > 8 && columnIndex < 11) {
					return {
						rowspan: 0,
						colspan: 0,
					};
				}
			} else if ((row.matchResult.matchStatus === 0 || row.matchResult.matchStatus === 1) && !row.matchResult.selectedModel?.mpn && !row.matchResult.selectedModel.priceData?.price) {
				// 完全匹配or待确认 且无型号和无价格
				if (columnIndex == 7) {
					return {
						rowspan: 1,
						colspan: 4,
					};
				} else if (columnIndex > 7 && columnIndex < 11) {
					return {
						rowspan: 0,
						colspan: 0,
					};
				}
			}
		},
		// 编辑
		editClick(row) {
			this.updateData = row;
			this.updateVisible = true;
		},
		// 修改确认
		async updateConfirm(dataObj) {
			try {
				// 创建Map存储tableData
				const tableMap = new Map(
					this.tableData.map(item => [item.bomData.rowIndex, item])
				);

				const { inquiryOrderNo } = this.dataForm;
				const { inquiryOrderDetailId } = dataObj;

				// 更新询价单详情
				const { data } = await this.$post(
					`/inquiry-orders/${inquiryOrderNo}/details/${inquiryOrderDetailId}`,
					dataObj,
					'api'
				);

				if (data.code !== 'C0000' || !data.result) {
					return;
				}

				const updatedItem = data.result;
				tableMap.set(updatedItem.bomData.rowIndex, updatedItem);

				// 如果没有匹配型号,直接更新表格数据
				if (!updatedItem.matchResult.selectedModel) {
					await this.updateTableData(tableMap, updatedItem);
					return;
				}

				// 更新价格信息
				await this.updatePriceInfo(tableMap, updatedItem);
			} catch (error) {
				console.error('更新确认失败:', error);
				this.$message.error('更新失败');
			}
		},
		// 更新表格数据的公共方法
		async updateTableData(tableMap, updatedItem) {
			this.tableData = Array.from(tableMap.values());
			this.calcNumber();
			this.selectTable();
			await this.updateUsageNum(updatedItem);
		},
		// 更新价格信息的方法
		async updatePriceInfo(tableMap, updatedItem) {
			this.isPriceLoading = true;
			try {
				// 请求价格数据
				const { data } = await this.$post('/products/price', {
					queryList: [{
						mpn: updatedItem.matchResult.selectedModel.mpn,
						quantity: updatedItem.bomData.usageNum || ''
					}]
				}, 'api');

				if (data.code === 'C0000' && data.result) {
					const priceData = data.result;

					// 更新价格信息
					if (this.shouldUpdatePrice(updatedItem)) {
						const itemWithPrice = tableMap.get(updatedItem.bomData.rowIndex);
						this.updateItemPrice(itemWithPrice, priceData);
						tableMap.set(updatedItem.bomData.rowIndex, itemWithPrice);
					}

					await this.updateTableData(tableMap, updatedItem);
				}
			} finally {
				this.isPriceLoading = false;
			}
		},
		// 判断是否需要更新价格
		shouldUpdatePrice(item) {
			return item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0;
		},
		// 更新单个项目的价格
		updateItemPrice(item, priceData) {
			item.matchResult.selectedModel = {
				...item.matchResult.selectedModel,
				priceData: priceData.find(p => p.mpn === item.matchResult.selectedModel.mpn)
			};
		},
		// 更换型号
		async changeConfirm(data) {
			try {
				this.isPriceLoading = true;
				const { inquiryOrderNo } = this.dataForm;
				const { inquiryOrderDetailId } = this.changeData.row.bomData;
				// 更新型号
				const { data: response } = await this.$put(
					`/inquiry-orders/${inquiryOrderNo}/details/${inquiryOrderDetailId}`,
					data,
					'api'
				);
				if (response.code !== 'C0000' || !response.result) {
					return;
				}
				const updatedItem = response.result;
				await this.updateItemWithPrice(updatedItem);
				await this.updateTableAndCalculations(updatedItem);
				this.$message.success('更换成功！');
				this.showVisible = false;
				this.changeFlag = true;
			} catch (error) {
				console.error('更换型号失败:', error);
				this.$message.error('更换型号失败');
			} finally {
				this.isPriceLoading = false;
			}
		},
		// 更换型号的更新价格
		async updateItemWithPrice(updatedItem) {
			if (!updatedItem.matchResult.selectedModel?.mpn) {
				return updatedItem;
			}
			try {
				const { data: priceResponse } = await this.$post('/products/price', {
					queryList: [{
						mpn: updatedItem.matchResult.selectedModel.mpn,
						quantity: updatedItem.bomData.usageNum || ''
					}]
				}, 'api');
				if (priceResponse.code === 'C0000' &&
					priceResponse.result?.length > 0) {
					const priceData = priceResponse.result.find(
						p => p.mpn === updatedItem.matchResult.selectedModel.mpn
					);

					if (priceData) {
						updatedItem.matchResult.selectedModel = {
							...updatedItem.matchResult.selectedModel,
							priceData
						};
					}
				}
			} catch (error) {
				console.error('获取价格数据失败:', error);
			}
			return updatedItem;
		},
		// 更换型号的更新表格数据
		async updateTableAndCalculations(updatedItem) {
			// 使用Map更新表格数据
			const tableMap = new Map(
				this.tableData.map(item => [item.bomData.rowIndex, item])
			);
			tableMap.set(updatedItem.bomData.rowIndex, updatedItem);
			this.tableData = Array.from(tableMap.values());
			// 等待所有计算完成
			await Promise.all([
				this.$nextTick(),
				this.calcNumber(),
				this.selectTable(),
				this.countTotal(),
				this.updateUsageNum(updatedItem)
			]);
		},
		// 新增确认
		addConfirm(form, value) {
			if (value) {
				this.dataForm.numberOfSets = value;
			}
			this.btnLoading = true;
			this.$post(`/inquiry-orders/${this.dataForm.inquiryOrderNo}/details`, form, 'api').then(({ data }) => {
				if (data.code === 'C0000') {
					if (data.result) {
						this.tableData.push(data.result);
						this.$message.success(data.message);

						this.tableData.forEach((item, index) => {
							if (item.bomData.rowIndex === data.result.bomData.rowIndex) {
								// 无匹配状态则不请求价格接口
								if (!this.tableData[index].matchResult.selectedModel) return;
								// 价格接口参数
								const paramsPrice = {
									queryList: [
										{
											mpn: this.tableData[index].matchResult.selectedModel
												.mpn,
											quantity: this.tableData[index].bomData.usageNum || '',
										},
									],
								};
								this.isPriceLoading = true;
								// 请求价格接口
								this.$post('/products/price', paramsPrice, 'api')
									.then(({ data }) => {
										if (data.code === 'C0000') {
											if (data.result) {
												const priceData = data.result;
												// 更换价格数据
												if (this.tableData[index].matchResult.matchStatus === 1 || this.tableData[index].matchResult.matchStatus === 0) {
													this.tableData[index].matchResult.selectedModel = {
														...this.tableData[index].matchResult.selectedModel,
														priceData: priceData.find((o) => o.mpn === this.tableData[index].matchResult.selectedModel.mpn),
													};
												}

												// 重新计算需求数和采购量
												this.calcNumber();
												this.unitPriceCount(this.tableData[index]);

												// 通知后台更新采购数量的参数
												const params = {
													inquiryDetails: [
														{
															inquiryOrderDetailId: this.tableData[index].bomData.inquiryOrderDetailId,
															usageNum: this.tableData[index].bomData.usageNum,
														},
													],
												};

												// 询价单编号
												const inquiryOrderNo = this.dataForm.inquiryOrderNo;

												// 通知后台更新采购数量
												this.$put(`/inquiry-orders/${inquiryOrderNo}/details/batch-update-purchase-quantity`, params, 'api');
											} else {
												this.$message.error(data.message);
											}
										}
									})
									.finally(() => {
										this.isPriceLoading = false;
									});
							}
						});

						this.addVisible = false;
					} else {
						this.$message.error(data.message);
					}
				}
			})
				.finally(() => {
					this.btnLoading = false;
					this.analyVisible = false;
				});
		},
		// 新增确认
		analyConfirm(inquiryDetails, value) {
			let params = Object.assign(
				{ numberOfSets: value, customerNo: this.customerNo },
				{ inquiryDetails }
			);
			this.btnLoading = true;
			this.$post('/bom/match', params, 'api').then(({ data }) => {
				if (data.code === 'C0000') {
					if (data.result) {
						this.analyVisible = false;
						this.$message.success(data.message);
						// 变更路由信息的订单编号
						this.$router.push({
							path: '/inquire-detail',
							query: {
								type: 'edit',
								inquiryOrderNo: data.result.inquiryOrderNo,
								cache: false
							}
						})
						let originData = data.result;

						// 检查originData和originData.inquiryDetails是否存在
						if (originData && originData.inquiryDetails) {
							// 筛选出状态为完全匹配或待确认的数据
							const matchStateObj = {
								...data.result,
								inquiryDetails: originData.inquiryDetails.filter((item) => item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0),
							};

							// 价格接口参数
							const paramsPrice = {
								queryList: matchStateObj.inquiryDetails.map((o) => {
									return {
										mpn: o.bomData.selectedModelMpn,
										quantity: o.bomData.usageNum || '',
									};
								}),
							};
							// 先渲染基础数据
							this.setData(originData);
							this.isloading = false;

							// 请求价格接口
							this.$post('/products/price', paramsPrice, 'api')
								.then(({ data }) => {
									if (data.code === 'C0000') {
										if (data.result) {
											const priceData = data.result;

											// 匹配结果中匹配状态为完全匹配或待确认的，将价格数据赋值给 matchResult.selectedModel.priceData，并与原本数据进行筛选
											originData.inquiryDetails.forEach((item) => {
												if (item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0) {
													item.matchResult.selectedModel = {
														...item.matchResult.selectedModel,
														priceData: priceData.find(
															(o) => o.mpn === item.bomData.selectedModelMpn
														),
													};
												}
											});
											// 价格数据合并后，重新渲染页面数据
											this.setData(originData);
											// 获取总金额
											this.countTotal();
											// 将数据保存到sessionStorage
											this.$utils.setSessionStorage('inquiryData', originData);
										} else {
											this.$message.error(data.message);
										}
									}
								})
						}
					} else {
						this.$message.error(data.message);
					}
				}
			})
				.finally(() => {
					this.btnLoading = false;
				});
		},
		// 处理询价结果数据
		setData(data) {
			this.dataForm.customerNo = data.customerNo;
			this.dataForm.numberOfSets = data.numberOfSets;
			this.dataForm.companyId = data.companyId;
			this.dataForm.createById = data.createById;
			this.dataForm.id = data.inquiryOrderNo;
			this.dataForm.inquiryNo = data.inquiryNo;
			this.dataForm.inquiryOrderId = data.inquiryOrderId;
			this.dataForm.inquiryOrderNo = data.inquiryOrderNo;
			this.tableData = data.inquiryDetails;
			this.calcNumber();
			this.selectTable();
			this.countTotal();
		},
		// 全选事件处理
		selectAllChangeEvent({ checked, records }) {
			this.selected = checked ? records : [];
			// 重新计算总金额
			this.countTotal();
		},
		// 单选事件处理
		selectChangeEvent({ checked, row }) {
			if (checked) {
				this.selected.push(row);
			} else {
				this.selected = this.selected.filter(item => item !== row);
			}
			// 重新计算总金额
			this.countTotal();
		},
		// 判断行是否可选
		selectable({ row }) {
			// 只有完全匹配且有价格且库存充足的行才可选
			return row.matchResult.matchStatus === 1 && this.unitPriceCount(row) > 0 && !this.isStockWarning(row);
		},
		// 自动勾选表格数据
		selectTable() {
			if (this.$refs.tableRef) {
				// 完全匹配且有价格且库存充足
				const selectableRows = this.tableData.filter(row =>
					row.matchResult.matchStatus === 1 && this.unitPriceCount(row) > 0 && !this.isStockWarning(row)
				);

				if (selectableRows.length > 0) {
					this.$refs.tableRef.setCheckboxRow(selectableRows, true);
					this.selected = selectableRows;
					// 在选择完成后计算总金额
					this.countTotal();
				}
			}
		},
		//删除
		delClick(ids) {
			let params = { inquiryOrderDetailIdList: ids };
			this.$confirm('确定要删除所勾选的数据吗？删除后无法恢复，请谨慎操作！', '提示', {
				type: 'warning',
			})
				.then(() => {
					ids.forEach((item) => {
						this.tableData.forEach((p, index) => {
							if (p.bomData.inquiryOrderDetailId === item) {
								this.tableData.splice(index, 1);
							}
						});
					});
					// 重新计算勾选数据
					this.selectTable();
					// 重新计算需求数和采购量
					this.calcNumber();
					// 重新计算总金额
					this.countTotal();

					const inquiryOrderNo = this.dataForm.inquiryOrderNo;
					this.$post(`/inquiry-orders/${inquiryOrderNo}/details/batch`, params, 'api').then(({ data }) => {
						if (data.code === 'C0000') {
							this.$message.success('删除成功');
						}
					});
				})
				.catch(() => {
					// 用户取消操作，忽略错误
				});
		},
		// 新增行
		addRowClick() {
			if (!this.dataForm.customerNo) {
				this.$message.error('请先填写项目名称！');
				return;
			}
			this.addVisible = true;
		},
		// 删除行
		delRowClick() {
			if (this.selected.length > 0) {
				let ids = this.selected.map((e) => e.bomData.inquiryOrderDetailId);
				this.delClick(ids);
			} else {
				this.$message({
					type: 'warning',
					message: '请先勾选数据！',
					offset: 380,
					grouping: true,
				});
			}
		},
		bindChanges() {
			this.changeFlag = true;
		},
		// 一键下单
		orderConfig() {
			// 完全匹配且有价格才可进行下单
			if (this.selected && this.selected.length > 0) {
				this.removeTabHandle(this.mainTabsActiveName);
				this.$router.push({
					path: '/order-detail',
					query: {
						type: 'add',
						cache: false,
						inquiryDetails: JSON.stringify(this.selected),
					},
				});
			}
		},
		removeTabHandle(tabName) {
			this.mainTabs = this.mainTabs.filter((item) => item.index !== tabName);
		},
		back() {
			this.removeTabHandle(this.mainTabsActiveName);
			this.$router.go(-1);
		},
		/**
		 * 计算小计(含税)
		 * 计算公式：小计 = 单价 * 采购量 （保留两位小数）
		 * 判断逻辑：
		 * 1.单价���存在，则为空
		 * 2.单价存在，且小计小于0.01，则返回0.01
		 * 3.单价存在，且小计大于0.01，返回小计
		 * @param row 行数据
		 */
		subtotal(row) {
			const usageNum = row.bomData.usageNum;
			if (!this.unitPriceCount(row) > 0) return;
			const subTotal = this.$calc.mul(this.unitPriceCount(row), usageNum).toFixed(2);

			return subTotal > 0.01 ? subTotal : '0.01';
		},
		// 确认型号 or 无法匹配
		changeUpdate(row, type) {
			const p = _.cloneDeep(row);
			const inquiryOrderNo = this.dataForm.inquiryOrderNo;
			const inquiryOrderDetailId = p.bomData.inquiryOrderDetailId;

			const param = {
				matchStatus: type === 'confirm' ? 1 : 2,
				inquiryOrderDetailId: p.bomData.inquiryOrderDetailId,
				matchMethod: 'artificial',
			};

			this.$put(`/inquiry-orders/${inquiryOrderNo}/details/${inquiryOrderDetailId}/status`, param, 'api').then(({ data }) => {
				if (data.code === 'C0000') {
					if (data.result) {
						this.tableData.forEach((item, index) => {
							if (item.bomData.rowIndex === data.result.bomData.rowIndex) {
								// 替换数据
								this.tableData[index].matchResult.matchStatus = data.result.matchResult.matchStatus;
								this.tableData[index].bomData.matchMethod = data.result.bomData.matchMethod;
								this.selectTable();
							}
						});
						this.$message.success('操作成功！');
					}
				}
			});
		},
		// 计算总金额
		countTotal() {
			let number = 0;
			if (this.selected && this.selected.length > 0) {
				this.selected.forEach((item) => {
					// 只有完全匹配且有价格的行才能计算
					if (item.matchResult.matchStatus === 1 && this.unitPriceCount(item) > 0) {
						number = this.$calc.add(number, this.$calc.mul(this.unitPriceCount(item) || 0, item.bomData.usageNum || 0));
					}
				});
			}
			this.totalAccount = number.toFixed(2);
			return this.totalAccount;
		},
		formatNum,
	},
	// 页面销毁时，清除sessionStorage的缓存数据
	deactivated() {
		this.$utils.removeSessionStorage('inquiryData');
	}
};
</script>

<style lang="scss">
.inquire-detail {
	padding: 40px;
	position: relative;

	.tfoot {
		background-color: #e8eaec;
		font-size: 14px;
		height: 45px;
		width: 100%;
		line-height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.iconBtn {
			display: inline-block;
			margin-left: 30px;
			cursor: pointer;
		}

		.iconBtn:first-child {
			margin-left: 22px;

			& .el-icon>svg {
				color: var(--xz-color-base);
			}
		}

		.el-icon {
			font-size: 24px;
			vertical-align: middle;
			margin-right: 10px;
		}

		.total {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 12px;

			.title {
				font-size: 14px;
				color: #000;
			}

			.price {
				font-size: 16px;
				color: var(--el-color-primary);
				line-height: normal;
			}
		}
	}

	.content {
		width: 100%;
	}

	.top {
		.upload-demo {
			.el-upload__text {
				p:nth-child(1) {
					font-size: 20px;
					margin-bottom: 10px;
				}

				p:nth-child(3) {
					margin: 10px 0 3px 0;
					color: #868686;
				}

				p:nth-child(4) {
					cursor: pointer;
					font-size: 14px;
					color: var(--el-color-primary);
				}
			}

			.el-upload-dragger {
				padding: 23px;
				height: 180px;
				border: 2px dashed #e5e5e5;
				background: unset;
			}
		}

		.search-div {
			width: 100%;
			height: 180px;
			padding: 30px 15px;
			border: 2px dashed #e5e5e5;
			border-radius: 8px;

			.item {
				margin-bottom: 24px;

				.value {
					float: right;
				}
			}

			.seque {
				float: right;
				margin-top: -10px;

				.btn {
					width: 35px;
					height: 35px;
					border-radius: 50%;
					background-color: var(--el-color-primary-dark-2);
					text-align: center;
					line-height: 32px;
					font-size: 20px;
					color: #fff;
					z-index: 2;
					position: relative;
					cursor: pointer;
				}

				.min {
					float: left;
				}

				.add {
					float: right;
				}

				.el-input {
					position: relative;
					float: left;
					height: 35px;
					width: unset;
					border-top: 1px solid var(--el-color-primary-dark-2);
					border-bottom: 1px solid var(--el-color-primary-dark-2);
					margin: 0 -15px;
					z-index: 1;

					.el-input__inner {
						text-align: center;
						width: 100px;
					}
				}
			}

			.project-name {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&>.el-input {
					width: 200px;
					height: 40px;
					float: right;
				}
			}
		}

		.analysis-overview {
			.title {
				font-size: 20px;
			}

			.info-list {
				margin-top: 40px;

				.el-row {
					text-align: center;

					.quanquan {
						width: 8px;
						height: 8px;
						border-radius: 50%;
						display: inline-block;
						margin-right: 4px;
					}
				}

				.info-list-ul {
					li {
						display: inline-block;
					}

					text-align: center;
				}

				.progress-bar {
					height: 16px;
					width: 100%;
					border-radius: 8px;
					margin: 17px 0 22px;
					margin-top: 100px;

					div {
						height: 100%;
						float: left;
						width: 33%;
						background-color: #ff8500;
						border-radius: 8px;
					}

					.right {
						float: right;
						background-color: #fe0000;
					}

					.ul-bar {
						li {
							position: relative;

							.number {
								position: absolute;
								color: #000000;
								font-size: 30px;
								top: -50px;
							}
						}
					}
				}
			}
		}
	}

	.good-info {
		font-size: 20px;

		.col-icon {
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;

			.del-icon {
				color: #ff453a;
				margin-left: 10px;
			}

			.sure-icon {
				margin-left: 10px;
			}
		}

		.tishi {
			color: #ff0000;
			text-align: center;

			.el-icon {
				font-size: 16px;
				vertical-align: middle;
				margin-right: 5px;
			}
		}

		.col-item {
			position: relative;
			text-align: left;

			p {
				white-space: nowrap;
				/*内容超宽后禁止换行显示*/
				overflow: hidden;
				/*超出部分隐藏*/
				text-overflow: ellipsis;
				/*文字超出部分以省略号显示*/
			}

			.locationInfo {
				font-size: 12px;
				color: red;
				border: 1px solid red;
				border-radius: 10px;
				padding: 0px 6px;
				display: inline-block;
				height: 18px;
				line-height: 16px;
				margin-top: 6px;
			}
		}

		.col-hover {
			.col-icon {
				display: none;
				top: -2px;
				right: 3px;
			}

			&:hover {
				.col-icon {
					display: block;
				}
			}
		}

		.heads {
			height: 80px;
			line-height: 80px;
			scroll-margin-top: 0px;

			.title {
				display: inline-block;
			}

			.title-info {
				background-color: #fde02a;
				border-radius: 4px;
				position: relative;
				padding: 2px 6px 3px 5px;
				line-height: 18px;
				font-size: 14px;
				margin-left: 10px;

				::before {
					content: '';
					width: 0px;
					height: 0px;
					border-top: 5px solid transparent;
					border-bottom: 5px solid transparent;
					border-right: 7px solid #fde02a;
					position: absolute;
					top: 5px;
					left: -5px;
				}
			}

			ul {
				float: left;
				font-size: 16px;

				li {
					float: left;
					margin-right: 37px;
					cursor: pointer;
					position: relative;

					&.isActive {
						color: var(--el-color-primary);
						font-size: 18px;

						&::after {
							content: '';
							width: 50%;
							height: 2px;
							background-color: var(--el-color-primary);
							position: absolute;
							bottom: 20px;
							left: 20%;
						}
					}
				}
			}

			.el-button {
				float: right;
			}
		}

		.result-item {
			display: flex;
			align-items: center;
			justify-content: center;

			.dot {
				font-size: 24px;
				width: 100%;
				height: 100%;
			}

			.check {
				color: green;
			}

			.confirmed {
				color: orange;
			}

			.nomatch {
				color: red;
			}

			>span {
				font-size: 16px;
			}
		}

		.btn-group {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 6px;

			.vxe-button {
				width: 66px;
				height: 28px;
				padding: 0;

				&+.vxe-button {
					margin-left: 0;
				}

				&>span {
					font-size: 12px;
				}
			}
		}

		.btn-operator {
			border-radius: var(--el-border-radius-base);
		}

		.usage-num {
			display: flex;
			flex-direction: column;

			.stock-tip {
				color: red;
				position: absolute;
				top: 100px;
				left: 50%;
				transform: translateX(-50%);
			}
		}

		// 添加超出库存警告样式
		.stock-warning {
			background-color: rgba(255, 0, 0, 0.1) !important; // 淡红色背景
		}

		// 自定义滚动条样式
		.vxe-table {
			&--body-wrapper {
				scrollbar-width: thin; // Firefox
				scrollbar-color: #c1c1c1 #f1f1f1; // Firefox

				&::-webkit-scrollbar {
					width: 8px;
					height: 8px;
				}

				&::-webkit-scrollbar-track {
					background: #f1f1f1;
					border-radius: 4px;
				}

				&::-webkit-scrollbar-thumb {
					background: #c1c1c1;
					border-radius: 4px;
					border: 2px solid #f1f1f1;

					&:hover {
						background: #a8a8a8;
					}
				}
			}

			// 防止滚动时表格内容闪烁
			&--body {
				will-change: transform;
				transform: translateZ(0);
				backface-visibility: hidden;
				perspective: 1000;
			}
		}

		.fixed-header {
			.vxe-table--header-wrapper {
				position: sticky;
				top: 0;
				z-index: 10;
				background: #fff;
				will-change: transform; // 优化性能
				transform: translateZ(0); // 开启硬件加速
			}

			.vxe-table--body-wrapper {
				will-change: transform; // 优化性能
				transform: translateZ(0); // 开启硬件加速
				backface-visibility: hidden; // 防止闪烁
				perspective: 1000; // 防止闪烁
			}

			.vxe-header--row {
				.fixed--hidden {
					visibility: inherit;
				}
			}
		}
	}

	.materialTable {
		.el-tooltip {
			white-space: normal !important;
		}

		.btn-operator {
			border-radius: var(--el-border-radius-base);
		}
	}

	.materialTable {
		.el-tooltip {
			white-space: normal !important;
		}
	}

	.high-light {
		em {
			color: green;
			font-weight: 700;
		}
	}

	.description-text-box {
		display: flex;
	}

	.break-word {
		white-space: normal;
		word-break: keep-all;
		display: -webkit-box;
		-webkit-line-clamp: 2; // 限制最多显示2行
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		word-break: break-word;
	}
}
</style>
