<template>
	<div style="position: relative" class="import-table">
		<el-tabs
			class="client-tabs"
			:class="{ 'no-bgd': data.type != 'view' }"
			v-model="activeName"
			@tab-change="tabChange"
		>
			<el-tab-pane label="商品信息" name="shop"> </el-tab-pane>
			<el-tab-pane label="付款记录" name="pay" v-if="data.type != 'add'">
				<ab-table class="materialTable" ref="table" stripe selectionWidth="45" indexLabel="序号"
					indexWidth="60" widths="120" isFilter="operator" :column-list="payColumns" :table-list="payTable" :dataListLoading="isloading"
				>
					<template #paymentStatus="scope">
						<span :class="getPayStatusClass(scope.row.transactionStatus)"></span>
						{{ transactionStatusText(scope.row.transactionStatus) }}
					</template>
					<template #file>
						<el-button type="text" link v-if="fileList && fileList.length" class="blue-color" @click="viewFile">查看</el-button>
					</template>
				</ab-table>
			</el-tab-pane>
			<el-tab-pane label="已失效" name="lose" v-if="data.type != 'add'">
			</el-tab-pane>
			<div class="order-box" v-if="activeName == 'shop' || activeName == 'lose'">
				<ab-table border class="materialTable" ref="table" :showEdit="activeName == 'shop'" :widths="activeName == 'shop' ? 150 : 60"
					:column-list="activeName == 'shop' ? addFlag ? shipColumns.filter((e) => e.props != 'returnOfGoods') : shipColumns.filter((e) => e.props != 'productMoQ') : shipColumns.filter((e) => e.props != 'productMoQ')"
					showOrder index-label="序号" indexWidth="80" :table-list="tableList" :dataListLoading="isloading"
				>
					<template #materialNo="scope">
									<span v-if="scope.row.bomData">
										<el-input :disabled="data.type == 'view'" v-model="scope.row.bomData.materialNo"></el-input>
									</span>
						<span v-else>{{ scope.row.userProductCode }}</span>
					</template>
					<template #usageNum="scope">
						<span v-if="scope.row.bomData">
							<el-input :disabled="data.type == 'view'" placeholder="请输入" v-model="scope.row.bomData.usageNum" type="number"
								@input="usageNumChange(scope.row)" @blur="validateIncrement(scope.row, scope.row.bomData.usageNum)" ></el-input>
						</span>
						<span v-else>{{ scope.row.quantityPurchased }}</span>
					</template>
					<template #productMoQ="scope">
						<div v-if="scope.row.matchResult && scope.row.matchResult.selectedModel" class="product-moq">
							<span>起订量：{{ scope.row.matchResult.selectedModel.priceData.min_buy_num }}</span>
							<span>增量：{{ scope.row.matchResult.selectedModel.priceData.min_increment }}</span>
						</div>
					</template>
					<template #productModel="scope">
						<span v-if="scope.row.matchResult && scope.row.matchResult.selectedModel">{{ scope.row.matchResult.selectedModel.mpn }}</span>
						<span v-else>{{ scope.row.productModel }}</span>
					</template>
					<template #brand="scope">
						<span v-if="scope.row.matchResult && scope.row.matchResult.selectedModel">{{ scope.row.matchResult.selectedModel.brand }}</span>
						<span v-else>{{ scope.row.brandName }}</span>
					</template>
					<template #productType="scope">
						<span v-if="scope.row.matchResult && scope.row.matchResult.selectedModel">{{ scope.row.matchResult.selectedModel.categoryName }}</span>
						<span v-else>{{ scope.row.subCategory }}</span>
					</template>
					<template #productPackaging="scope">
						<span v-if="scope.row.matchResult && scope.row.matchResult.selectedModel">{{ scope.row.matchResult.selectedModel.productPackaging }}</span>
						<span v-else>{{ scope.row.package }}</span>
					</template>
					<template #parameter="scope">
						<span v-if="scope.row.matchResult && scope.row.matchResult.selectedModel">{{ scope.row.matchResult.selectedModel.parameter }}</span>
						<span v-else>{{ scope.row.description }}</span>
					</template>
					<template #unitPrice="scope">
						<div v-if="scope.row.matchResult && scope.row.matchResult.selectedModel.priceData.price">
							<div class="col-item">
								<p v-if="scope.row.matchResult.selectedModel && scope.row.matchResult.selectedModel.priceData.price">
									<span>{{ scope.row.matchResult.selectedModel.priceData.price }}</span>
								</p>
								<p class="tishi" v-else>
									<el-icon> <WarningFilled /> </el-icon>暂无报价，无法下单
								</p>
							</div>
							<div class="col-icon">
								<el-popover placement="bottom" title="价格梯度" :width="280" trigger="hover">
									<template #reference>
										<p v-if="scope.row.matchResult.selectedModel && scope.row.matchResult.selectedModel.priceData.price_list">
											<el-icon><View /></el-icon>
										</p>
									</template>
									<template #default v-if="scope.row.matchResult.selectedModel && scope.row.matchResult.selectedModel.priceData.price_list">
										<p v-for="(item, index) in scope.row.matchResult.selectedModel.priceData.price_list" :key="item">
											<span>{{ index }}+：</span><span>￥{{ item }}</span>
										</p>
									</template>
								</el-popover>
							</div>
						</div>
						<div v-else>{{ scope.row.unitPrice }}</div>
					</template>
					<template #total="scope">
						<div class="col-item">
							<span v-if="scope.row.matchResult && scope.row.matchResult.selectedModel">
								{{ subtotal(scope.row) }}
							</span>
							<span v-else>{{ scope.row.totalPrice }}</span>
						</div>
					</template>
					<template #returnOfGoods="scope">
						<span class="status-span" :class="getReturnColor(scope.row)"></span>
						{{ getDesc(scope.row) }}
					</template>
					<template #operator="scope">
						<template v-if="activeName == 'shop'">
							<el-button type="text" link v-if="data.type == 'add'" class="red-color" @click="delRow(scope.row)">删除</el-button>
							<el-button type="text" link v-if="data.type != 'add' && data.paymentStatus !== PaymentStatus.COMPLETED" style="color: red" @click="rowEdit(scope.row)">取消</el-button>
						</template>
					</template>
				</ab-table>
				<el-row>
					<el-col :span="6">
						<span>总金额（含税）</span>
						<div class="number">{{ totalAccount }}</div>
					</el-col>
				</el-row>
			</div>
		</el-tabs>
		<el-dialog class="file-dialog" append-to-body title="银行水单" v-model="fileDialog" top="10vh" width="500px">
			<ul>
				<li v-for="(item, index) in fileList" :key="index">
					<ab-svg :name="getSuffix(item.disName)"></ab-svg>
					<u @click="handerOreview(item.fileUrl)">{{ item.disName }}</u>
				</li>
			</ul>
			<div class="dialog-footer">
				<el-button @click="fileDialog = false">取 消</el-button>
			</div>
		</el-dialog>
		<ab-preview :preview="preview"></ab-preview>
	</div>
</template>

<script>
import { PaymentStatus } from '@/constant/index';


export default {
	name: 'import-order-table',
	data() {
		return {
			isloading: false,
			activeName: 'shop',
			dataForm: {},
			payColumns: [
				{ props: 'platformTransactionId', labels: '交易流水编号', widths: 100 },
				{ props: 'paymentStatus', labels: '付款状态', widths: 100, slot: 'slot' },
				{ props: 'payType', labels: '付款方式', widths: 100 },
				{ props: 'totalAmount', labels: '付款金额', widths: 100 },
				{ props: 'payTime', labels: '付款时间', widths: 100 },
				{ props: 'file', labels: '银行水单', widths: 60, slot: 'slot' },
			],
			payTable: [],
			shipColumns: [
				{ props: 'productModel', labels: '型号', widths: 80, slot: 'slot' },
				{ props: 'materialNo', labels: '物料编码', widths: 100, slot: 'slot' },
				{ props: 'brand', labels: '品牌', widths: 100, slot: 'slot' },
				{ props: 'productType', labels: '品类', widths: 100, slot: 'slot' },
				{ props: 'productPackaging', labels: '封装', widths: 100, slot: 'slot' },
				{ props: 'parameter', labels: '规格', widths: 80, slot: 'slot' },
				{ props: 'usageNum', labels: '购买数量', widths: 80, slot: 'slot' },
				{ props: 'productMoQ', labels: '起订量', widths: 100, slot: 'slot' },
				{ props: 'unitPrice', labels: '单价（含税）', widths: 100, slot: 'slot' },
				{ props: 'total', labels: '总金额（含税）', widths: 100, slot: 'slot' },
				{ props: 'returnOfGoods', labels: '状态', widths: 100, slot: 'slot' },
			],
			ids: [], //列表已有物料id集合
			fileList: [], //银行水单
			fileDialog: false,
			preview: {
				show: false,
			},
			accept:
				'.bmp,.jpg,.png,.tif,.gif,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.txt,.zip,.rar,.ofd',
			routeQuery: {},
			canceledList: [], // 已失效数据,
			orderStatusList: [], // 订单状态常量
			paymentMethodList: [], // 付款方式常量
			paymentStatusList: [], // 付款状态常量
			tableList: [], // 列表数据
			PaymentStatus: PaymentStatus,
			totalAccount: null, // 总金额
		};
	},
	computed: {
		addFlag() {
			return this.routeQuery.type === 'add';
		}
	},
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	watch: {
		data: {
			handler(val) {
				if (val.inquiryDetails && val.inquiryDetails.length > 0) {
					this.tableList =
						this.routeQuery.type === 'add' || this.activeName === 'shop'
							? val.inquiryDetails.filter((e) => !e.returnOfGoods && !e.cancelOfGoods && !e.refund)
							: val.canceledList || [];
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.routeQuery = this.$route.query || {};
		this.initData();
		if (this.routeQuery.type === 'add') {
			this.tableList = JSON.parse(this.routeQuery.inquiryDetails) || [];
			this.countTotal();
		} else {
			if (this.activeName === 'shop') {
				this.tableList = this.data.inquiryDetails.filter(
					(e) => !e.returnOfGoods && !e.cancelOfGoods && !e.refund)
			} else {
				this.tableList = this.data.canceledList || [];
			}
		}
	},
	methods: {
		initData() {
			// 订单状态
			this.$get('/orders/constants', undefined, 'api').then(({ data }) => {
				if (data.code === 'C0000') {
					// 订单状态
					this.orderStatusList = data.result.OrderDetailStatus;
					// 付款方式
					this.paymentMethodList = data.result.paymentMethod;
					// 付款状态
					this.paymentStatusList = data.result.paymentStatus;
				}
			});
		},
		// 已失效的颜色
		getReturnColor(row) {
			let cls = 'status-A5A5A5';
			if (row.cancelOfGoods) {
				cls = 'status-E21010';
			}
			return cls;
		},
		// 订单状态
		orderStatusText(status) {
			return this.orderStatusList.find((item) => item.value == status)?.label;
		},
		// 付款状态
		transactionStatusText(status) {
			return this.paymentStatusList.find((item) => item.value == status)?.label;
		},
		// 已失效的状态
		getDesc(row) {
			return this.orderStatusText(row.orderDetailStatus);
		},
		getSuffix(obj) {
			if (!obj) {
				return;
			}
			let index = obj.lastIndexOf('.') || 0;
			let svg = obj.substring(index + 1).toLowerCase();
			return this.accept.indexOf(svg) == -1 ? 'accessory' : svg;
		},
		viewFile() {
			this.fileDialog = true;
		},
		handerOreview(fileUrl) {
			this.preview.fileUrl = fileUrl;
			this.preview.show = true;
		},
		getPayStatusClass(value) {
			const statusClasses = {
				[PaymentStatus.UNPAID]: 'status-E21010', // 未支付
				[PaymentStatus.PENDING]: 'status-FF8400', // 待处理
				[PaymentStatus.COMPLETED]: 'status-0AB2A8', // 已支付
			};
			return statusClasses[value] + ' status-span' || '';
		},
		rowEdit(row) {
			const orderNo = this.routeQuery.orderNo;
			const orderDetailId = row.orderDetailId;

			this.$post(
				`/orders/${orderNo}/details/${orderDetailId}/cancel`,
				undefined,
				'api'
			).then(({ data }) => {
				if (data.code === 'C0000') {
					this.$message.success('操作成功！');
					this.$emit('updatedForm', JSON.parse(JSON.stringify(row)));
				} else {
					this.$message.error(data.message);
				}
			});
		},
		usageNumChange(row) {
			row.bomData.usageNum = row.bomData.usageNum.replaceAll('.', '');
			// 获取总金额
			this.countTotal()
		},
		validateIncrement(row, originalValue) {
			const minIncrement =
				row.matchResult.selectedModel?.priceData?.min_increment;
			// 购买数量必须是增量的倍数
			if (minIncrement && row.bomData.usageNum % minIncrement !== 0) {
				this.$message.error(`购买数量必须是增量 ${minIncrement} 的倍数`);
				row.bomData.usageNum = originalValue;
				this.aloneBlur(row);
				return;
			}
		},
		/**
		 * 购买量:usageNum
		 * 起订量:min_buy_num
		 * 增量：min_increment
		 *
		 * 当购买量等于起订量时，则购买数量=单用量；
		 * 当购买量小于起订量时，则购买数量=起订量；
		 * 当购买量大于起订量时，则购买数量=单用量，若是超出的部分，没有满足“增量”的倍数，直接往上取整倍
		 * @param row 行数据
		 */
		aloneBlur(row) {
			let num = 0;
			if (!row.matchResult.selectedModel) return;

			const usageNum = row.bomData.usageNum;
			const minBuyNum = row.matchResult.selectedModel.priceData?.min_buy_num;
			const minIncrement = row.matchResult.selectedModel.priceData?.min_increment;

			if (usageNum > minBuyNum) {
				num = this.$calc.add(minBuyNum, Math.ceil((usageNum - minBuyNum) / minIncrement) * minIncrement);
			} else if (usageNum < minBuyNum) {
				num = minBuyNum;
			} else {
				num = usageNum;
			}

			row.bomData.usageNum = num;
		},
		/**
		 * 计算小计(含税)
		 * 计算公式：小计 = 单价 * 采购量 （保留两位小数）
		 * 判断逻辑：
		 * 1.单价不存在，则为空
		 * 2.单价存在，且小计小于0.01，则返回0.01
		 * 3.单价存在，且小计大于0.01，返回小计
		 * @param row 行数据
		 */
		subtotal(row) {
			const usageNum = row.bomData.usageNum;
			if (!this.unitPriceCount(row) > 0) return;
			const subTotal = this.$calc.mul(this.unitPriceCount(row), usageNum).toFixed(2);

			return subTotal > 0.01 ? subTotal : '0.01';
		},
		// 单价
		unitPriceCount(row) {
			let unitPrice = 0;
			const selectedModel = row.matchResult.selectedModel || {};
			const priceTierIds = selectedModel.priceData.price_list;
			const usageNum = row.bomData.usageNum;

			// 空值检查
			if (!priceTierIds || typeof priceTierIds !== 'object' || !usageNum) return unitPrice;

			try {
				// 价格梯度降序排序
				const sortedEntries = Object.entries(priceTierIds).sort(
					(a, b) => b[0] - a[0]
				);

				// 遍历价格梯度，找到匹配的价格
				for (const [quantity, price] of sortedEntries) {
					if (usageNum >= parseInt(quantity, 10)) {
						unitPrice = price;
						break;
					}
				}
			} catch (error) {
				console.error('Error in unitPriceCount:', error);
			}

			return unitPrice;
		},
		// 计算总金额
		countTotal() {
			if (this.routeQuery.type === 'add') {
				let number = 0;
				if (this.tableList && this.tableList.length > 0) {
					this.tableList.forEach((item) => {
						number = this.$calc.add(number, this.$calc.mul(this.unitPriceCount(item) || 0, item.bomData.usageNum || 0));
					});
				}
				this.totalAccount = number.toFixed(2);
				return this.totalAccount;
			} else {
				this.totalAccount = this.data.totalAmount || 0;
				return this.totalAccount;
			}
		},
		tabChange() {
			this.isloading = true;
			setTimeout(() => {
				if (this.data.paymentTransaction) {
					this.payTable = [{
						platformTransactionId: this.data.paymentTransaction.platformTransactionId,
						transactionStatus: this.data.paymentTransaction.transactionStatus,
						payType: this.paymentMethodList.find((item) => item.value == this.data.paymentTransaction.paymentPlatform)?.label,
						totalAmount: this.data.paymentTransaction.receivedAmount,
						payTime: this.data.paymentTransaction.transactionTime,
					}] || [];
				}
				this.isloading = false;
			}, 500);
			if (this.activeName == 'pay') {
				this.fileList = [];
				let params = {
					data: {
						sourceBillId: this.data.id,
						sourceBillType: 'yinhang-exchange',
						attachType: '110',
					},
				};
				this.$post('/api/attactment/list', params).then(({ data }) => {
					if (data.code === 'C0000') {
						this.fileList = data.result ? data.result.list || [] : [];
					}
				});
			} else if (this.activeName == 'lose') {
				this.tableList = this.data.canceledList || [];
			} else if (this.activeName == 'shop') {
				this.tableList = this.data.inquiryDetails || [];
			}
		},
		delRow(row) {
			if (
				this.data.inquiryDetails.filter(
					(e) => e.bomData.rowIndex != row.bomData.rowIndex
				).length < 1
			) {
				this.$message.error('最少保留一条！');
				return;
			}
			this.$confirm('确定要删除吗，删除后无法恢复，请谨慎操作？', '提示', {
				type: 'warning',
			})
				.then(() => {
					if (!this.data.id) {
						const index = this.data.inquiryDetails.findIndex(
							(e) => e.bomData.rowIndex === row.bomData.rowIndex
						);
						if (index !== -1) {
							this.tableList.splice(index, 1);
							// 重新计算总金额
							this.countTotal();
						}
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.import-table {
	.materialTable {
		button + .el-button {
			margin-left: 0;
		}
	}
}
.file-dialog {
	.el-dialog__body {
		padding-bottom: 20px;
	}
	ul {
		min-height: 100px;
		li {
			padding: 4px 15px;
			background-color: #ebf7fc;
			border-radius: 6px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			.icon-svg {
				width: 16px;
				height: 16px;
				margin-right: 5px;
			}
			u {
				cursor: pointer;
				&:hover {
					color: #00458e;
				}
			}
		}
	}
	.dialog-footer {
		text-align: right;
	}
}
.client-tabs {
	.el-tabs__header {
		margin-bottom: 40px;
	}
	&.no-bgd {
		.el-tabs__item.is-active {
			color: #000;
			&::after {
				display: none !important;
			}
		}
	}
	.col-icon {
		position: absolute;
		top: 10px;
		right: 10px;
		cursor: pointer;

		.del-icon {
			color: #ff453a;
			margin-left: 10px;
		}

		.sure-icon {
			margin-left: 10px;
		}
	}

	.tishi {
		color: #ff0000;

		.el-icon {
			font-size: 16px;
			vertical-align: middle;
			margin-right: 5px;
		}
	}
}
.product-moq {
	display: flex;
	flex-direction: column;
}
</style>
