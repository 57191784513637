<template>
  <div class="import-orders-detail page-detail" v-loading="orderLoading">
    <el-form :model="dataForm" ref="dataForm" label-position="top" class="dataForm" :rules="rules">
      <div class="contentInfo">
        <div class="logisticsInfo">
          <div class="title">
            <h3>配送信息</h3>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="快递" prop="exprId">
                <el-select v-model="dataForm.exprId" @change="dataForm.exprTypeId = ''">
                  <el-option v-for="(option, idx) in expressOptionsList" :key="idx" :label="option.label"
                    :value="option.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="detailedAddress">
                <!-- 进口 我的地址 -->
                <ab-select2 v-model="dataForm.detailedAddress" :isNotClear="true" url="/receivingAddress/list"
                            @handleSelect="handleSelect" inputKey="address" @handlerEdit="handlerEdit" label="收货地址" required @handlerAdd="handlerAdd" class="address-select">
                </ab-select2>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="goodsInfo">
          <import-order-table ref="good-table" :data="goodsInfoData"></import-order-table>
        </div>
      </div>
      <div class="footers">
        <el-button @click="back">返回</el-button>
        <el-button type="primary" :loading="btnLoading" @click="sumitForm()">提 交</el-button>
      </div>
    </el-form>

    <delivery-address-dialog @updateAddress="handleSelect" :dialog="addressVisible"></delivery-address-dialog>
  </div>
</template>
<script>
import importOrderTable from './import-order-table'
import deliveryAddressDialog from '../user-center/box/address-dialog'

export default {
  name: 'import-order-detail',
  components: {
    importOrderTable,
    deliveryAddressDialog
  },
  props: {
    routeQuery: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      expressOptionsList: [],
      goodsInfoData: {},
      activeIndex: 0,
      expressOptions: [],
      logvisible: false,
      addressVisible: { show: false, data: {} },
      orderstatus: 1,
      orderLoading: false,
      dataForm: {
        entry: [],

      },
      rules: {
        exprId: [
          { required: true, message: '快递为空', trigger: ['blur', 'change'] }
        ],
        expectDate: [
          { required: false, message: '期望交货日期不能为空', trigger: ['blur', 'change'] }
        ],
        detailedAddress: [
          { required: true, message: '收货地址为空', trigger: ['blur', 'change'] }
        ]
      },
      btnLoading: false
    }
  },
  computed: {
    mainTabs: {
      get() {
        return this.$store.state.mainTabs
      },
      set(val) {
        this.$store.commit('updateMainTabs', val)
      }
    },
    mainTabsActiveName: {
      get() { return this.$store.state.mainTabsActiveName },
      set(val) { this.$store.commit('updateMainTabsActiveName', val) }
    }
  },
  mounted() {
    this.initData()
    this.init()
  },
  methods: {
    disabledDate(time) {
      return time.getTime() < new Date().setHours(0, 0, 0, 0);
    },
    updateAddress(item) {
      this.dataForm.detailedAddress = item.detailedAddress
      this.dataForm.userAddressId = item.userAddressId
    },
    handleSelect(item) {
      this.dataForm.countryId = item.countryId
      this.dataForm.cityId = item.countryId
      this.dataForm.countyId = item.countyId
      this.dataForm.addressId = item.id
      this.dataForm.address = item.areaAddressDesc + '/' + item.address + '/' + item.contactPerson + '/' + item.contactPhone
    },
    handlerAdd() {
      this.addressVisible.show = true
    },
    handlerEdit() {
      this.addressVisible.id = this.dataForm.addressId
      this.addressVisible.show = true
    },
    getStatusTimeAxis() {
      this.logvisible = true

    },
    expressClick(index) {
      this.activeIndex = index
    },
    initData() {
      // 快递公司
      this.$get('/orders/constants', { type: 'express' }, 'api').then(({ data }) => {
        if (data.code === 'C0000') {
          this.expressOptions = data.result
          this.expressOptionsList = this.expressOptions
        }
      })
    },
    init() {
      this.deleteEntry = []
      //下单
      if (this.routeQuery.id || this.dataForm.id) { // 编辑/详情
        this.$post('/orderBase/get', this.routeQuery.id || this.dataForm.id).then(({ data }) => {
          if (data.code === 'C0000') {
            this.dataForm = data.result
            this.goodsInfoData.inquiryDetails = data.result.detailds
            this.goodsInfoData.orderStatus = data.result.orderStatus
            this.goodsInfoData.id = data.result.id
          }
        })
      }
      if(this.routeQuery.inquiryDetails) {
        this.goodsInfoData.inquiryDetails = JSON.parse(this.routeQuery.inquiryDetails)
      }
      this.goodsInfoData.type = this.routeQuery.type
      this.$nextTick(() => {
        this.$refs.dataForm.resetFields()
      })
    },
    removeTabHandle(tabName) {
      this.mainTabs = this.mainTabs.filter(item => item.index !== tabName)
    },
    back() {
      if(!this.dataForm.id) {
          this.$confirm('当前订单未保存，确定返回吗？', '提示', {
              type: 'warning',
          }).then(() => {
              this.removeTabHandle(this.mainTabsActiveName)
              this.$router.go(-1)
          }).catch(()=>{
            // 取消
          })
      }else {
        this.removeTabHandle(this.mainTabsActiveName)
        this.$router.go(-1)
      }
    },
    // 提交
    sumitForm() {
      this.$refs.dataForm.validate(valid => {
        if (valid) {
          let details = []
          let arr = this.goodsInfoData.inquiryDetails || []
          if (arr && arr.length > 0) {
            arr.map(item => {
              details.push({
                productModel: item.matchResult.selectedModel.mpn,
                userProductCode: item.bomData.materialNo,
                quantityPurchased: item.bomData.usageNum
              })
            })
          }

          // 提交参数
          const data = {
            courierChannel: this.dataForm.exprId,
            expectedDeliveryTime: this.dataForm.expectDate,
            userAddressId: this.dataForm.addressId,
            orderDetails: details
          }

          // 校验
          if(!details.length) {
            this.$message.error('商品不能为空！')
            return
          }
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            if(!element.bomData.usageNum) {
              this.$message.error(`第${index + 1}行【购买数量】不能为空！`)
              return
            }else{
              if(!element.matchResult.selectedModel || !element.matchResult.selectedModel.priceData.price) {
                this.$message.error(`第${index + 1}行没有报价，无法下单！`)
                return
              }
            }
          }

          // 提交请求
          this.$post('/orders/bom', data, 'api', this, 'btnLoading').then(({data}) => {
            if (data.code == 'C0000') {
              this.$message({
                type: 'success',
                message: data.message,
                offset: 380,
                grouping: true,
              })
              this.removeTabHandle(this.mainTabsActiveName)
              this.$router.push(
                {
                  path: '/order-list',
                }
              )

            }
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          // 获取所有带有 form-item 类的元素
          const formItems = this.$refs.dataForm.$el.getElementsByClassName('el-form-item is-error');

          if (formItems && formItems.length > 0) {
            // 滚动到第一个错误的表单项
            formItems[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            });
          }
          return;
        }
      })
    },
  },
  watch: {
    routeQuery: {
      handler: function(val, oldval) {
        this.dataForm = {}
        setTimeout(() => {
          this.init()
        }, 500);
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
.import-orders-detail {
  position: relative;
  .address-select{
    .el-input{
      width: 800px;
    }
  }
  .expressDiv {
    ul {
      display: flex;

      li {
        width: 150px;
        height: 45px;
        background: #EBF7FC;
        border: 1px solid #F0F2F5;
        border-radius: 8px;
        text-align: center;
        line-height: 42px;
        margin-left: 20px;
        cursor: pointer;
        position: relative;

        &:first-child {
          margin: 0;
        }
      }

      .activeLi {
        border: 1px solid #00458E;
      }
    }

    .icon-div {
      width: 0;
      height: 0;
      border-bottom: 30px solid #00458E;
      border-left: 30px solid transparent;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 0 0 8px 0;

      .el-icon {
        position: absolute;
        right: 1px;
        color: #fff;
        bottom: -27px;
      }
    }
  }

  .order-step {
    padding: 30px;
    margin: 0 8%;

    .ab-icon {
      .el-step__icon-inner {
        padding: 13px 16px;
        height: 64px;
        width: 64px;
        background-color: var(--el-color-primary-light-9);
        border-radius: 8px;
      }

      .icon-svg {
        width: 33px;
        height: 36px;
      }
    }

    .el-step__line {
      left: 55%;
      right: -41%;
      margin: 20px;
      background: none;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(to right, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 50%, transparent 50%);
      background-size: 15px 1px;
    }

    .el-step__title {

      font-weight: 500;
    }

  }

  .contentInfo {
    h3 {
      font-size: 18px
    }

    .title {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;

      h3 {
        color: #000000;
        line-height: 40px;
      }

      .progress {
        height: 45px;
        border: 1px solid #C4C6CF;
        cursor: pointer;
        border-radius: 22.5px;
        line-height: 45px;
        padding: 0 20px;

        .text {
          font-size: 14px;
          color: #111111;
        }
      }
    }
  }

  .logisticsInfo {
    padding-bottom: 10px;


    .el-col.el-col-8 {
      margin-bottom: 20px;
    }

    .supplierName {
      width: 100%;
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #f0f2f5;
      line-height: 24px;

      .supplier {
        padding: 5px 12px;
        height: 24px;
        margin-right: 20px;
        line-height: 14px;
        border-radius: 4px;
        background-color: #f0f2f5;

        span {
          color: #fe2424;
        }
      }

      .icon-svg {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin: 0 10px;
        cursor: pointer;
      }

      p {
        font-size: 22px;
        font-weight: bold;
      }
    }

    .receivingAddress {
      font-size: 14px;
      font-weight: 400;
      margin: 18px 0 17px 0;

      .icon-svg {
        width: 11px;
        height: 16px;
        vertical-align: bottom;
      }
    }

    .el-alert {
      margin: 32px 0 20px;
    }

    .remark {
      margin-top: 32px;

      .el-textarea {
        .el-textarea__inner {
          min-height: 45px !important;
          padding-top: 11px;
          box-shadow: 0 0 0 1px transparent;

          &:focus {
            box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset;
          }
        }
      }
    }
  }

  .goodsInfo {
    margin-bottom: 30px;

    >h3 {
      margin-bottom: 37px;
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      justify-content: space-between;

      h3 {
        margin: 0;
      }
    }

    .materialTable {
      margin-bottom: 36px;
    }

    .el-row {
      .el-col.el-col-6 {
        span {
          font-size: 14px;
          color: #636871;
        }

        div {
          font-size: 30px;
          font-weight: 500;
          margin-top: 20px;
        }
      }
    }
  }
}

.orderStatus {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 0.96rem;
  height: 0.34rem;
  line-height: .3rem;
  background: linear-gradient(34deg, #FFE42B, #FFE42B);
  border-radius: 0 0 0.18rem 0.18rem;
  font-size: .14rem;
  text-align: center;
  cursor: pointer;
}
</style>
