<template>
  <div class="impor-orders-list">
    <ab-list-query :tabs="tabs" @handleTabs="handleTabs" :isActive="activedName">
      <template #right>
        <el-row :gutter="20">
          <el-col :span="4">
            <el-input placeholder="订单编号" v-model.trim="formSearch.orderNo" clearable @clear="clearOrderNo">
            </el-input>
          </el-col>
          <el-col :span="4">
            <el-input placeholder="商品型号" v-model.trim="formSearch.productModel" clearable @clear="clearProductModel">
            </el-input>
          </el-col>
          <el-col :span="4" v-if="!formSearch.isFailure">
            <el-select placeholder="订单状态" v-model="formSearch.orderStatus" @change="searchForm" clearable>
              <el-option v-for="item in orderStatusArray" :key="item" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4" v-if="!formSearch.isFailure">
            <el-select placeholder="付款状态" v-model="formSearch.paymentStatus" @change="searchForm" clearable>
              <el-option v-for="item in paymentStatusArray" :key="item" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <ab-timeSearch class="searchBox" startSrc="创建日期起" endSrc="创建日期止" v-model="dateTimeRange"
              @searchEvent="changeTimeRange"></ab-timeSearch>
          </el-col>
          <el-col :span="4">
            <el-button @click="searchForm" class="pub-btn">搜索</el-button>
            <el-button link class="btn-svg btn-clear" @click="resetForm"><ab-svg name="qingchu"></ab-svg>清除</el-button>
          </el-col>
        </el-row>

      </template>
      <template #toolbar>
        <el-button :loading="isBtnLoading" @click="exportFile" class="pub-btn">导出</el-button>
      </template>
      <template #table>
        <div class="order-box" :dataListLoading="isloading" v-for="item in tableData" :key="item.orderNo" >
          <div class="order-info">
            <div class="box">
              <p>订单号:<span class="copy-value" @click="showDetail(item.orderNo, item.orderStatus)">{{ item.orderNo }}</span></p>
              <div class="copy" @click="copy(item.orderNo)">复制</div>
            </div>
            <p class="time">{{ item.createdAt }}</p>
          </div>
          <div class="order-product">
            <div class="product">
              <div class="item" v-for="p in (showAllDetails[item.orderNo] ? item.orderDetailList : item.orderDetailList.slice(0, 2))" :key="p.orderDetailId">
                <div class="info">
                  <p class="title" @click="showDetail(item.orderNo, item.orderStatus)">{{ p.description }}</p>
                  <p class="model" @click="showDetail(item.orderNo, item.orderStatus)">{{ p.productModel }}</p>
                  <p>{{ p.package }}</p>
                  <p>{{ p.brandName }}</p>
                </div>
                <div class="price">
                  <p>￥ {{ p.unitPrice }}</p>
                  <p>× {{ p.quantityPurchased }} pcs</p>
                </div>
              </div>
              <div v-if="item.orderDetailList.length > 2">
                <div v-if="!showAllDetails[item.orderNo]" class="more" @click="onShowAllDetails(item.orderNo)">
                  共{{ item.orderDetailList.length }}个物料，点击更多查看更多
                </div>
                <div v-else class="more" @click="onShowAllDetails(item.orderNo)">收起</div>
              </div>
            </div>
            <div class="total">
              <p>￥ {{ item.totalAmount }}</p>
              <p class="user">
                <ab-svg name="user" />
                {{ item.orderAddress.contactPerson }}
              </p>
            </div>
            <div class="detail">
              <p class="text" @click="showDetail(item.orderNo, item.orderStatus)">订单详情</p>
              <p class="status">{{ item.orderStatusText }}</p>
            </div>
            <div class="operate">
              <el-button v-if="item.orderStatus === OrderStatus.PENDING_PAYMENT" link @click="cancel(item.orderNo)">取消</el-button>
              <el-button v-if="item.orderStatus === OrderStatus.PENDING_PAYMENT" link @click="pay(item.totalAmount, item.orderNo)">付款</el-button>
              <el-button link @click="onBuyAgain(item.orderNo)">再次购买</el-button>
              <el-button link @click="onPrintContract(item.orderNo)">打印合同</el-button>
              <el-button link @click="onRefund">我要退款</el-button>
            </div>
          </div>
        </div>
      </template>
      <template #page>
        <ab-page :pageTotal="page.total" :currentPage='page.currentPage' :pageSize="page.pageSize"
          :pageSizes="page.pageSizes" @searchForm="pageSearch"></ab-page>
      </template>
    </ab-list-query>
    <textarea style="width: 0; height: 0" id="inputCopy">这是幕后黑手</textarea>
    <el-dialog title="付款" v-model="payDialog.show" :close-on-click-modal="false">
      <div v-html="payDialog.data"></div>
    </el-dialog>
    <ab-logistics-status ref="logistics-status" :limitDrawer="limitDrawer"></ab-logistics-status>
    <!-- 付款 弹窗 -->
    <pay-dialog :visible="visibleData.show" :data="visibleData.data" @cancel="visibleData.show = false" @success="getListData"></pay-dialog>
    <!-- 购买确认 弹窗 -->
    <buy-again-dialog :visible="buyAgainData.show" :orderNo="buyAgainData.orderNo" @cancel="buyAgainData.show = false" @confirm="handleBuyAgainSubmit"></buy-again-dialog>
    <!-- 退货退款 弹窗 -->
    <refund-return-dialog :visible="refundData.show" @cancel="refundData.show = false"></refund-return-dialog>
  </div>
</template>

<script>
import { download } from '@/utils/download';
import { OrderStatus } from '@/constant/index';
import payDialog from './box/pay-dialog'
import BuyAgainDialog from './box/buy-again-dialog.vue';
import RefundReturnDialog from './box/refund-return-dialog.vue';


export default {
  name: 'inquire-list',
  components: {
    payDialog,
    BuyAgainDialog,
    RefundReturnDialog,
  },
  data() {
    return {
      visibleData: {
        show: false,
        data: {
          amount: 0,
          orderNo: '',
          availableBalance: 0
        }
      },
      isloading: false,
      btnLoading: false,
      visible: false,
      isBtnLoading: false,
      payDialog: {show: false,},
      tabs: [
        { name: '全部', value: '', state: '' },
        { name: '待支付', value: '', state: 'pending_payment' },
        { name: '处理中', value: '', state: 'processing', type: 1 },
        { name: '已发货', value: '', state: 'shipped' },
        { name: '已取消', value: '', state: 'cancelled', type: 2 },
        { name: '退货申请中', value: '', state: 'refund_requested' },
        { name: '已退款', value: '', state: 'refund' },
      ],
      orderStatusArray: [],
      paymentStatusArray: [],
      orderStatusColor: [],
      orderStatusObj: {},
      formSearch: {},
      dateTimeRange: '',
      page: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        total: 0
      },
      tableData: [],
      query: {},
      limitDrawer: {show: false, id: ''},
      buyAgainData: {show: false, orderNo: ''}, // 再次购买
      refundData: {show: false}, // 退货退款
      showAllDetails: {}, // 是否显示全部物料
      OrderStatus: OrderStatus,
    }
  },
  watch: {
    $route(to) {
      if (to.path === '/order-list') {
        this.query = this.$route.query
        if(this.query.isHome) {
          this.formSearch = {}
          this.dateTimeRange = ''
          if (this.query.params) {
            this.formSearch[this.query.params] = this.query.status
            this.activedName = this.query.status
          }
        }
        this.getInit()
        this.getListData()
      }
    },
  },
  mounted() {
    this.query = this.$route.query
    if (this.query.params) {
      this.formSearch[this.query.params] = this.query.status
      this.activedName = this.query.status
    }
    this.getInit()
    this.getListData()
  },
  methods: {
    // 切换是否全部显示物料
    onShowAllDetails(orderNo) {
      this.showAllDetails[orderNo] = !this.showAllDetails[orderNo];
    },
    // 导出
    exportFile() {
      this.isBtnLoading = true
            this.$get('/orders/export-list', {
              orderNo: this.formSearch.orderNo,
              orderStatus: this.formSearch.orderStatus,
              paymentStatus: this.formSearch.paymentStatus,
              createTimeStart: this.formSearch.createTimeStart,
              createTimeEnd: this.formSearch.createTimeEnd
            },'api').then(({data}) => {
                if(data.code === 'C0000') {
                  const { content, filename } = data.result;
                  download(content, filename);
                }else {
                    this.$message.error(data.message || '导出失败，请联系管理员')
                }
            }).finally(() => {
                this.isBtnLoading = false
            })
    },
    // 付款
    pay(totalAmount, orderNo) {
      this.visibleData.data.amount = totalAmount
      this.visibleData.data.orderNo = orderNo
      this.visibleData.show = true
    },
    // 优化搜索条件
    clearOrderNo() {
      this.formSearch.orderNo = '';
      this.searchForm();
    },
    clearProductModel() {
      this.formSearch.productModel = '';
      this.searchForm();
    },
    // 搜索
    searchForm() {
      this.getListData()
    },
    // 分页搜索
    pageSearch(obj) {
      this.page.currentPage = obj.currentPage;
      this.page.pageSize = obj.pageSize;
      this.getListData();
    },
    //日期
    changeTimeRange(obj) {
      this.formSearchcreateTime = obj
      if (obj) {
        this.formSearch.createTimeStart = obj[0]
        this.formSearch.createTimeEnd = obj[1]
      } else {
        this.formSearch.createTimeStart = ''
        this.formSearch.createTimeEnd = ''
      }
      this.searchForm()
    },
    getInit() {
      this.$get('/orders/constants', undefined, 'api').then(({ data }) => {
        if (data.code === 'C0000') {
          this.orderStatusArray = data.result.orderStatus;
          this.paymentStatusArray = data.result.paymentStatus;
        }
      })
    },
    // 列表数据
    getListData() {
      this.isloading = true
      this.$get('/orders', {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderNo: this.formSearch.orderNo,
        productModel: this.formSearch.productModel,
        orderStatus: this.formSearch.orderStatus,
        paymentStatus: this.formSearch.paymentStatus,
        createTimeStart: this.formSearch.createTimeStart,
        createTimeEnd: this.formSearch.createTimeEnd,
      }, 'api').then(res => {
        if (res.data.code === 'C0000') {
          this.tableData = res.data.result.data
          this.page.total = res.data.result.pagination.total
        }
      }).finally(() => {
        this.isloading = false
      })

      this.$get('/orders/status-count',{},'api').then(({ data }) => {
        if (data && data.code === 'C0000') {
            this.tabs[1].value = data.result.pending_payment;
            this.tabs[2].value = data.result.processing;
            this.tabs[3].value = data.result.shipped;
            this.tabs[4].value = data.result.cancelled;
            this.tabs[5].value = data.result.refund_requested;
            this.tabs[6].value = data.result.refund;
          }
      })
    },
    // 详情/编辑
    showDetail(orderNo, orderStatus) {
      this.$router.push({
        path: '/order-detail',
        query: {
          type: 'view',
          orderNo: orderNo,
          orderStatus: orderStatus,
          cache: false
        }
      })
    },
    // 取消
    cancel(orderNo) {
      this.$confirm('确定要取消吗？', '提示', {
        type: 'warning',
      }).then(() => {
        this.$post(`/orders/${orderNo}/cancel`, undefined,'api').then(({ data }) => {
          if (data.code === 'C0000') {
            this.$message.success(data.message || '操作成功！')
            this.getListData()
          }
        })
      }).catch((e) => {
        console.log(e)
      })
    },
    // 切换tabs
    handleTabs(item, rowObj) {
      this.activedName = item
      delete this.formSearch.orderStatus
      delete this.formSearch.paymentStatus
      this.formSearch.orderStatus = rowObj.state
      this.getListData()
    },
    // 重置
    resetForm() {
      this.formSearch = {}
      this.dateTimeRange = ''
    },
    // 复制
    copy(value) {
			var input = document.getElementById('inputCopy'); // 找到虚拟的输入框
			input.value = value; // 修改文本框的内容
			input.select(); // 选中文本
			document.execCommand('copy'); // 执行浏览器复制命令
			this.$message({
				type: 'success',
				message: '复制成功!',
				grouping: true,
				offset: 380,
			});
		},
    // 再次购买
    onBuyAgain(orderNo) {
      this.buyAgainData.show = true
      this.buyAgainData.orderNo = orderNo
    },
    // 再次购买提交
    handleBuyAgainSubmit(orderNo) {
      this.$post(`/orders/${orderNo}/buy-again`, undefined, 'api').then(({ data }) => {
        if (data.code === 'C0000') {
          this.$message.success(data.message)
          this.getListData()
        }
      }).finally(() => {
        this.buyAgainData.show = false
      })
    },
    // 打印合同
    onPrintContract(orderNo) {
      this.$get(`/orders/${orderNo}/print-contract`, undefined, 'api').then(({ data }) => {
        if (data.code === 'C0000') {
          // base64转pdf文件，下载
          const link = document.createElement('a');
          link.href = `data:application/pdf;base64,${data.result.fileContent}`;
          link.download = data.result.fileName;
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
    },
    // 我要退款
    onRefund() {
      this.refundData.show = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.impor-orders-list {
  // height: 100vh;
  overflow-y: auto;
  // 滚动条样式
  &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: #aaa;

      &:hover {
          background: #999;
      }
  }

  &::-webkit-scrollbar-track {
      background: transparent;
  }
  .order-box {
    border: 1px solid #dfdfdf;
    margin-bottom: 32px;
    .order-info {
      padding: 12px 16px;
      background-color: #f6f6f6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #a8a5ad;
      .box {
        display: flex;
        align-items: center;
        color: var(--el-color-primary);
        .copy-value {
          cursor: pointer;
        }
        .copy {
          cursor: pointer;
          margin-left: 12px;
          padding: 3px 8px;
          border: 1px solid #dfdfdf;
          color: #333;
          border-radius: 4px;
        }
      }
    }
    .order-product {
      display: flex;
      box-sizing: border-box;
      .product {
        flex: 3;
        .item {
          display: flex;
          padding: 14px;
          .info {
            flex: 3;
            > p  {
              color: #a8a5ad;
              margin-bottom: 4px;
            }
            .title {
              color: #333;
              max-width: 300px;
              text-wrap: wrap;
              word-break: break-all;
              cursor: pointer;
            }
            .model {
              cursor: pointer;
            }
          }
          .price {
            flex: 1;
            color: #333;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #dfdfdf;
          }
        }
        .more {
          padding: 10px 0 !important;
          text-align: center;
          color: green;
          cursor: pointer;
        }
      }
      & > div:not(:first-child) {
        flex: 1;
        padding: 16px;
        border-left: 1px solid #dfdfdf;
        text-align: center;
      }
      .total {
        .user {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 8px;
          color: #a8a5ad;
          .icon-svg__user {
            width: 24px;
            height: 24px;
          }
        }
      }
      .detail {
        .text {
          cursor: pointer;
        }
        .status {
          color: #a8a5ad;
          margin-top: 8px;
        }
      }
      .operate {
        display: flex;
        flex-direction: column;
        align-items: center;
        .el-button + .el-button {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
}

.ab-list-query {
  .list-content {
    margin-bottom: 200px;
  }
}
.list-page {
  padding: 24px 0;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  height: 80px;
  width: 100%;
}
</style>

