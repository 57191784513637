<template>
  <div>
    <el-table
      class="table-common-table"
      :border="border"
      :stripe="stripe"
      :height="height"
      :show-summary="showSummary"
      :summary-method="summaryMethod"
      :span-method="arraySpanMethod"
      :row-class-name="rowClassName"
      :cell-class-name="cellClassName"
      :max-height="maxHeight"
      :ref="refName"
      :scrollbar-always-on="scrollbar"
      :data="tableList"
      v-loading="dataListLoading"
      @select="select"
      @row-click="rowClick"
      @row-dblclick="fn"
      @cell-click="cellclick"
      @header-click="headerClick"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
      @select-all="selectAll"
      @current-change="handleCurrentChange"
      :highlight-current-row="singleCheck"
      :header-cell-class-name='headerCellClassName'
      :element-loading-spinner="svg"
      element-loading-svg-view-box="-10, -10, 50, 50"
      element-loading-background="rgba(256, 256, 256, 0.6)"
      :element-loading-text="loadingText"
    >
      <el-table-column
        type="selection"
        :width="selectionWidth || (showSummary ? 60 : 45)"
        align="center"
        v-if="showSelection"
        :selectable="selectable"
      ></el-table-column>
      <el-table-column
        :width="downLoadWidth"
        align="center"
        label="附件"
        fixed="left"
        v-if="isDownload"
      >
        <template #default="scope">
          <div @click="downLoadFile(scope.row)">
            <ab-svg name="attachment" class="table-uplaod-icon"></ab-svg>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="20" v-if="showSign" fixed="left">
        <template #default="scope">
          <slot name="sign" :row="scope.row"></slot>
        </template>
      </el-table-column>
      <el-table-column
        :label="indexLabel"
        :width="indexWidth"
        fixed="left"
        v-if="showOrder"
        type="orderNumber"
        :align="indexAlign"
      >
        <template #default="scope">{{ scope.$index + 1 }}</template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columnList"
        :key="index"
        :prop="item.props"
        :align="item.align"
        :label="item.labels"
        :min-width="item.widths"
        :fixed="item.fixed"
        :show-overflow-tooltip="true"
      >
      <template #header v-if="isFilter == item.props ">
        {{item.labels}} <tableFilter :columnsOptions="columnList" :filterArry="filterArry" @filterColumns="filterColumns"></tableFilter>
      </template>
        <template #default="scope">
          <div v-if="item.props == 'status' || item.props == 'isEnabled'">
            <slot
              name="operators"
              :row="scope.row"
              :index="scope.$index"
              :prop="item.props"
            ></slot>
          </div>
          <div v-else-if="item.slot === 'slot'">
            <slot
              :name="item.props"
              :row="scope.row"
              :index="scope.$index"
              :prop="item.props"
            ></slot>
          </div>
          <div v-else-if="item.type == 'money'">
            {{ $filters.formatMoney(scope.row[item.props]) }}
          </div>
          <div v-else>{{ scope.row[item.props] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        v-if="showEdit"
        :width="widths"
        align="left"
        fixed="right"
      >
      <template #header v-if="isFilter == 'operator'">
        操作 <tableFilter :columnsOptions="columnList" :filterArry="filterArry" @filterColumns="filterColumns"></tableFilter>
      </template>
        <template #default="scope" style="padding-right: 20px">
          <slot name="operator" :row="scope.row" :index="scope.$index"></slot>
          <el-popover
            popper-class="more-oper"
            v-if="showMoreEdit || scope.row.showMore"
            placement="bottom"
            trigger="click"
          >
            <slot
              name="moreOper"
              :row="scope.row"
              :index="scope.$index"
            ></slot>
            <template #reference>
              <el-button
                type="primary"
                link
                class="is-link"
                >更多</el-button
              >
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
const svg = `
      <path class="path" d="
        M 30 15
        L 28 17
        M 25.61 25.61
        A 15 15, 0, 0, 1, 15 30
        A 15 15, 0, 1, 1, 27.99 7.5
        L 15 15
      " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
    `
import tableFilter from './table-filter.vue'
export default {
  components: {
    tableFilter
  },
  name: "ab-table",
  props: {
    headerCellClassName: {
      type: Function,
      default: function () {},
    },
    sortChange: {
      type: Function,
      default: function () {},
    },
    cellclick: {
      type: Function,
      default: function () {},
    },
    headerClick: {
      type: Function,
      default: function () {},
    },
    selectable: {
      type: Function,
      default: function (val) {
        if (Object.keys(val).length > 0) {
          return true;
        } else {
          return false;
        }
      },
    },
    select: {
      type: Function,
      default: function () {},
    },
    selectAll: {
      type: Function,
      default: function () {},
    },
    indexAlign: {
      default: "center",
    },
    indexLabel: {
      type: String,
      default: "#",
    },
    indexWidth: {
      type: [Number, String],
      default: 40,
    },
    rowClick: {
      type: Function,
      default: function () {},
    },
    fn: {
      type: Function,
      default: function () {},
    },
    handleSelectionChange: {
      type: Function,
      default: function () {},
    },
    handleSelectAll: {
      type: Function,
      default: function () {},
    },
    refName: {
      type: String,
      default: 'table-common-table'
    },
    loadingText: {
      type: String,
      default: '加载中...'
    },
    tableList: {
      type: Array,
    },
    showOrder: {
      type: Boolean,
    },
    showSign: {
      type: Boolean,
    },
    showSelection: {
      type: Boolean,
    },
    columnList: {
      type: Array,
    },
    showEdit: {
      type: Boolean,
    },
    showMoreEdit: {
      type: Boolean,
    },
    widths: {
      type: [String, Number],
      default: "170",
    },
    showContent: {
      type: Boolean,
    },
    dataListLoading: {
      type: Boolean,
    },
    height: {
      type: [String, Number]
    },
    maxHeight: {
      type: [String, Number],
    },
    border: {
      type: Boolean,
      default: false,
    },
    showSummary: {
      type: Boolean,
      default: false,
    },
    summaryMethod: {
      type: Function,
      default: function () {},
    },
    arraySpanMethod:{
      type: Function,
      default: function () {}
    },
    handleCurrentChange: {
      type: Function,
      default: function () {},
    },
    singleCheck: {
      type: Boolean,
      default: false,
    },
    rowClassName: {
      type: Function,
      default: function () {},
    },
    cellClassName: {
      type: Function,
      default: function () {},
    },
    isDownload: {
      type: Boolean,
      default: false,
    },
    downLoadFile: {
      type: Function,
      default: function () {},
    },
    selectionWidth: {
      default: 0,
    },
    downLoadWidth: {
      type: String,
      default: "40",
    },
    stripe: {
      type: Boolean,
      default: false,
    },
    scrollbar: {
      type: Boolean,
      default: true,
    },
    isFilter: {
      type: String,
      default: '',
    },
    filterArry: {
      type: Array,
      default: []
    }
  },
  data() {
    return {};
  },
  watch: {
    columnList(v) {},
  },
  methods: {
    filterColumns (array) {
      this.$emit('filterColumns', array)
    }
  }
};
</script>
<style lang="scss" scope>
.table-common-table {
  .el-button--text {
    // color: #0b7aff;
    color: var(--el-color-primary);
  }
  .caret-wrapper {
    vertical-align: top;
    margin-top: -4px;
  }
  .table-uplaod-icon {
    height: 15px;
    width: 14px;
    cursor: pointer;
    transform: rotate(-100deg);
    -ms-transform: rotate(-100deg);
  }
}
.more-oper {
  min-width: 0;
  padding: 10px;
  .el-button {
    display: block;
    margin: 0 auto;
    padding: 6px;
    border: 0;
  }
}
.el-scrollbar__thumb:hover {
  background-color: #4a4d50 !important;
}
</style>
