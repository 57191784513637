<template>
  <el-dialog
    :title="title"
    custom-class="account-dialog"
    v-model="dialog.show"
    :close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formSearch"
      v-loading="loading.content"
      :rules="rules"
      ref="formSearch"
      label-position="top"
    >

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="SWIFT/联行号" prop="number">
            <el-autocomplete
              v-model="formSearch.number"
              :fetch-suggestions="bankQuerySearchAsync"
              placeholder="请输入英文名称，如有括号，请采用英文输入法下的括号"
              @select="bankChange"
              @blur.stop="bankBlur"
              maxlength="200"
              size="mini"
              clearable
            >
              <template #default="{ item }">
                <span class="addr">{{ item.code }}</span>
              </template>
            </el-autocomplete>
            <!-- <bank-component
              v-model="formSearch.numberId"
              :desc="formSearch.number"
              @change-data="selectNumber"
              placeholder="请选择"
              input-key="number"
              desc-key="number"
            ></bank-component> -->
          </el-form-item>
        </el-col>

        <el-col :span="12" class="country-form-item">
          <el-form-item label="国家地区" prop="countryName">
            <ab-popover interpretText="银行所在的国家或地区，银行汇款时必须提供" :textLength="4.8" width="260" top="-30"></ab-popover>

            <ab-select
              v-model="formSearch.countryName"
              url="/bas/basArea/listCountry"
              :urlData="{ type: 1,isEnabled: 1,keyWord: formSearch.countryName }"
              inputKey="keyWord"
              descKey="name"
              placeholder
              :disabled="isDisabled || !formSearch.number"
              @handleSelect="countryChange"
            ></ab-select>
          </el-form-item>
        </el-col>

        <el-col :span="12" v-if="isDisabled">
          <el-form-item label="开户银行" prop="bank">
            <el-select
              v-model="formSearch.bank"
              @change="selectBank"
              disabled
              size="mini"
            >
              <el-option
                v-for="(option, idx) in bankOptions"
                :key="idx"
                :label="option.name"
                :value="option.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12" v-else>
          <el-form-item label="开户银行" prop="bankName">
            <el-input :disabled="!formSearch.number" size="mini" v-model="formSearch.bankName" placeholder="请输入开户银行" clearable></el-input>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="12">
          <el-form-item label="银行区域" prop="areaIdDesc">
            <ab-address v-model="formSearch.areaIdDesc" :addressData="areaOptions"></ab-address>
          </el-form-item>
        </el-col> -->

        <el-col :span="12">
          <el-form-item label="银行账号" prop="account">
            <el-input size="mini" v-model="formSearch.account" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="币别" prop="currencyIdDesc">
            <ab-select
              v-model="formSearch.currencyIdDesc"
              url="/bas/basCurrency/list"
              placeholder="请选择"
              @handleSelect="selectCurrency"
            ></ab-select>
          </el-form-item>
        </el-col>

        <!-- <el-col :span="12">
          <el-form-item label="银行地址">
            <el-input size="mini" v-model="formSearch.address" disabled></el-input>
          </el-form-item>
        </el-col> -->

        <el-col :span="12">
          <el-form-item label="账户名称" prop="accountName">
            <el-input size="mini" v-model="formSearch.accountName" placeholder="请输入" clearable></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="　">
            <ab-fileBtn v-model="fileData" @change="changefileData"></ab-fileBtn>
          </el-form-item>
        </el-col>
      </el-row>

      <ab-alert :fileList="fileList"></ab-alert>
    </el-form>

    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="handlerSave" :loading="loading.confirmBtn">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
export default {
  components: {

  },
  name: 'ab-account-dialog', // 供应商账户弹窗
  props: {
    dialog: {
      type: Object,
      default: () => {
        return {}
      }
    },
    title: {
      type: String,
      default: '新增账户'
    },
    isDefault: {
      tpye: Boolean,
      default: false
    },
    account: {
      type: Object
    }
  },
  data () {
    const numberVai = (rule, value, callback) => {
      if (value) {
        if(value.length == 8 || value.length == 11) {
          if(!/^[a-zA-Z]+$/.test(value) && !/[A-Za-z].*[0-9]|[0-9].*[A-Za-z]/.test(value)) {
            callback(new Error(`长度为${value.length}位时，只能是纯字母或字母+数字`))
          }
        }else if(value.length == 12) {
          if(!/^[0-9]*$/.test(value)) {
            callback(new Error('长度为12位时，只能是纯数字'))
          }
        }else {
          callback(new Error('长度只能为8位，11位，12位'))
        }
        callback()
      } else {
        callback()
      }
    }
    return {
      countryData: [
        {
          label: '国家中文名称（简体）',
          prop: 'name'
        },
        {
          label: '国家英文名称',
          prop: 'nameEn'
        },
        {
          label: '国家中文名称（繁体）',
          prop: 'nameTra'
        },
        {
          label: '简称',
          prop: 'number'
        }
      ],
      loading: {
        content: false,
        confirmBtn: false
      },

      // 弹窗数据
      formSearch: {},
      rules: {
        // areaIdDesc: [
        //   { required: true, message: '请选择银行区域', trigger: 'change' }
        // ],
        currencyIdDesc: [
          { required: true, message: '请选择币别', trigger: 'change' }
        ],
        swiftCode: [
          { required: true, message: '请输入联行号', trigger: 'blur' }
        ],
        bank: [
          { required: true, message: '请选择开户银行', trigger: ['blur', 'change'] }
        ],
        bankName: [
          { required: true, message: '请输入开户银行', trigger: ['blur', 'change'] }
        ],
        number: [
          { required: true, message: '请选择SWIFT/联行号', trigger: ['blur', 'change'] },
          { validator: numberVai, trigger: ['change', 'blur'] }
        ],
        countryName: [
          { required: true, message: '请选择国家地区', trigger: ['blur', 'change'] }
        ],
        account: [
          { required: true, message: '请输入银行账号', trigger: 'blur' },
          { max: 200, message: '最大支持200位字符长度', trigger: 'blur' },
          { pattern: /^((?!:|"|%|&|\*|<|>|（|）|@|#|\^|~|\[|\]|\||\s).)*$/, message: '请不要输入下列符号："%&*<>@#~^[]|及空格', trigger: 'blur' }
        ],
        accountName: [
          { required: true, message: '请输入账户名称', trigger: 'blur' },
          { max: 200, message: '最大支持200位字符长度', trigger: 'blur' },
          { pattern: /^((?!:|"|%|&|\*|<|>|@|#|\^|~|\[|\]|\|).)*$/, message: '请输入规范字符', trigger: 'blur' }
        ],
        address: [
          { max: 200, message: '最长支持200位字符长度', trigger: 'blur' }
        ]
      },

      // 附件数据
      fileData: {
        sourceBillId: '',
        attachType: '020',
        sourceBillType: 'Supplier',
        sourceBillNumber: ''
      },
      fileList: [],

      // 地址数据
      areaOptions: [],

      // 银行数据
      bankOptions: [],
      isDisabled: false
    }
  },
  watch: {
    account: {
      handler (obj) { // 初始化
        this.fileData.sourceBillId = obj.sourceBillId
        this.formSearch.parentId = obj.parentId
        this.formSearch.accountName = obj.accountName
      },
      deep: true
    }
  },
  mounted () {
    // 地址数据
    this.$post('/bas/basArea/listCountry',
      {
        pageNum: 1,
        pageSize: 9999,
        type: 1
      })
      .then(({ data }) => {
        if (data.code === 'C0000') {
          this.areaOptions = data.result.list
        }
      })
  },
  methods: {
    bankBlur() {
      this.formSearch.number = this.formSearch.number.trim()
      setTimeout(() => {
          let data = {
            exactNumber: this.formSearch.number,
            pageNum: 1, pageSize: 10
          }
          this.$post('/bas/basBank/pageList', data)
            .then(({ data }) => {
              if (data.code === 'C0000') {
                this.isDisabled = data.result && data.result.list && data.result.list.length
              }
            })
      }, 500)
    },
    // 选择联行号
    bankChange (v) {
      if (!v) {
        this.formSearch.bank = ''
        this.formSearch.bankName = ''
        this.formSearch.countryId = ''
        this.formSearch.countryName = ''
      } else {
        this.formSearch.bank = v.id
        this.formSearch.bankName = v.name
        this.formSearch.number = v.number
        this.formSearch.countryId = v.countryId
        this.formSearch.countryName = v.countryIdDesc
        this.isDisabled = true
        this.selectNumber(v)
      }
    },
    // 银行号
    bankQuerySearchAsync (queryString, callback) {
      let data = {
        'number': queryString,
         pageNum: 1, pageSize: 10
      }
      let results = []
      this.$post('/bas/basBank/pageList', data)
        .then(({ data }) => {
          if (data.code === 'C0000') {
            results = data.result.list
          }
        })
        .finally(() => {
          callback(results)
        })
    },
    countryChange (arr) {
      if(arr) {
        this.formSearch.countryId = arr.id
        this.formSearch.countryName = arr.name
      }
    },
    selectCurrency(row) {
      if (row && row.id) {
        this.formSearch.currencyId = row.id
      } else {
        this.formSearch.currencyId = ''
      }
    },

    // 选择SWIFT
    selectNumber (data) {
      this.bankOptions = []
      this.formSearch.bank = ''
      this.$nextTick(() => {
        this.$refs.formSearch.clearValidate('bank')
      })
      if (data) {
        this.formSearch.number = data.number
        this.$post('/bas/basBank/pageList', { isEnabled: 1, number: this.formSearch.number })
          .then(({ data }) => {
            if (data.code === 'C0000') {
              this.bankOptions = data.result.list
              if (this.bankOptions.length === 1) {
                this.formSearch.bank = this.bankOptions[0].id
                this.selectBank()
              }
            }
          })
      }
    },

    // 选择银行
    selectBank () {
      this.bankOptions.map(item => {
        if (item.id === this.formSearch.bank) {
          this.formSearch.countryId = item.countryId
          this.formSearch.countryName = item.countryIdDesc
        }
      })
    },

    changefileData (file) {
      if (file && file.id) {
        let temp = []
        if (!this.fileList) {
          temp.push(file)
          this.fileList = temp
        } else {
          this.fileList.push(file)
        }
      }
    },

    handlerSave () {
      this.$refs.formSearch.validate(valid => {
        if (valid) {
          // let flag = false
          //     this.$post('/bas/client/basSupplier/checkSupplierAccountEntry', { parentId: this.formSearch.parentId, bank: this.formSearch.bank, account: this.formSearch.account, currencyId: this.formSearch.currencyId }).then(({ data }) => {
          //     if (data && data.code === 'C0000') {
          //       if(!data.result) {
          //         flag = true
          //         }
          //       }
          //   })
            if(!this.isDisabled && this.fileList.length < 1) {
                  this.$message({
                      type: 'error',
                      message: '新账号请上传银行资料盖章件! ',
                      appendTo: '.account-dialog',
                      grouping: true,
                      offset: 160
                    })
                  return
            }
          this.loading.confirmBtn = true
          // this.$set(this.formSearch, 'areaId', this.formSearch.areaIdDesc.join(','))
          this.formSearch.isDefault = this.isDefault || 0
          this.$post('/bas/client/basSupplier/submitAccount', this.formSearch)
            .then(({ data }) => {
              if (data.code === 'C0000') {
                this.$message({
                  type: 'success',
                  message: data.message,
                  appendTo: '.account-dialog',
                  grouping: true,
                  offset: 160
                })
                this.$emit('on-reload', {form: this.formSearch})
                this.handleClose()
              }
            })
            .finally(() => {
              this.loading.confirmBtn = false
            })
        }
      })
    },

    handleClose () {
      this.formSearch = {
        parentId: this.formSearch.parentId,
        accountName: this.formSearch.accountName
      }
      this.$nextTick(() => {
        this.$refs.formSearch.clearValidate()
      })
      this.dialog.show = false
    }
  }
}
</script>
<style lang="scss">
  .account-dialog {
    .el-dialog__body {
      padding: 30px 40px;
    }
    .popover-title{
        position: absolute;

        left: 59px;
    }
    .popover-title1{
        position: absolute;
    }
  }
</style>
