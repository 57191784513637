<template>
    <div class="impor-orders-list">
        <ab-list-query :tabs="tabs" :isActive="activedName" @handleTabs="handleTabs">
            <template #right>
                <el-row :gutter="20">
                    <el-col :span="4">
                        <el-input placeholder="询价单号" v-model.trim="formSearch.inquiryOrderNo" clearable
                            @clear="clearInquiryOrderNo">
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-input placeholder="项目名称" v-model.trim="formSearch.customerNo" clearable
                            @clear="clearCustomerNo">
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <ab-timeSearch class="searchBox" startSrc="创建日期起" endSrc="创建日期止" v-model="dateTimeRange"
                            @searchEvent="changeTimeRange"></ab-timeSearch>
                    </el-col>
                    <el-col :span="4">
                        <el-button @click="searchForm" class="pub-btn">搜索</el-button>
                        <el-button link class="btn-svg btn-clear" @click="resetForm"><ab-svg
                                name="qingchu"></ab-svg>清除</el-button>
                    </el-col>
                </el-row>
            </template>
            <template #toolbar>
                <el-upload class="upload-inquire-list" :disabled="noCertifiedFlag" :action="action" drag
                    :headers="headers" :before-upload="beforeAvatarUpload" :on-success="uploadSuccess" multiple>
                    <el-button type="primary" :loading="isBtnLoading" :disabled="noCertifiedFlag">上传 BOM</el-button>
                </el-upload>
                <el-button :loading="isBtnLoading" @click="exportFile" class="pub-btn">导出</el-button>
                <el-button @click="downTempate" class="pub-btn">下载BOM模板</el-button>
            </template>
            <template #table>
                <ab-table class="materialTable" ref="table" stripe showSelection selectionWidth="45" indexWidth="50"
                    widths="110" isFilter="operator" :handleSelectionChange="handleSelectionChange"
                    :column-list="columns" :table-list="tableData" :dataListLoading="isloading"
                    @filterColumns="filterColumns">
                    <template #inquiryNo="props">
                        <span class="number-primary" @click="showDetail(props.row)">{{ props.row.inquiryOrderNo
                            }}</span>
                    </template>
                    <template #operator="scope">
                        <el-button link type="primary" @click="exportFileSingle(scope.row)">导出</el-button> <span
                            class="mini-line"></span>
                        <i class="dividingLine"></i>
                        <el-button link type="primary" @click="showDetail(scope.row)">编辑</el-button> <span
                            class="mini-line"></span>
                        <i class="dividingLine"></i>
                        <el-button link class="red-color" style="margin-left: 0"
                            @click="delClick(scope.row)">删除</el-button>
                    </template>
                </ab-table>
            </template>
            <template #page>
                <ab-page :pageTotal="page.total" :currentPage='page.currentPage' :pageSize="page.perPage"
                    :pageSizes="page.pageSizes" @searchForm="pageSearch"></ab-page>
            </template>
        </ab-list-query>
        <ab-analy-dialog :btnLoading="btnLoading" @confirm="analyConfirm" v-model:visible="showVisible"
            :tableData="tableList" @cancel="showVisible = false"></ab-analy-dialog>
    </div>
</template>

<script>
import vueCookie from 'vue-cookies'
import { download } from '@/utils/download'

export default {
    name: 'inquire-list',
    components: {
    },
    data() {
        return {
            isBtnLoading: false,
            isloading: false,
            dateTimeRange: '',
            tabs: [
            ],
            formSearch: {},
            activedName: '',
            action: this.$adornUrl('/inquiryBase/bomAnalysis'),
            headers: { Authorization: vueCookie.get('token') },
            page: {
                currentPage: 1,
                perPage: 10,
                pageSizes: [10, 20, 50, 100],
                total: 0
            },
            columns: [
                {
                    props: 'inquiryNo',
                    labels: '询价单号',
                    widths: 140,
                    slot: 'slot'
                },
                {
                    props: 'customerNo',
                    labels: '项目名称',
                    widths: 100
                },
                {
                    props: 'createdAt',
                    labels: '创建时间',
                    widths: 140
                },
                {
                    props: 'detailCount',
                    labels: '询价项数',
                    widths: 80
                },
                {
                    props: 'toBeConfirmedCount',
                    labels: '待确认',
                    widths: 80
                },
                {
                    props: 'perfectMatchCount',
                    labels: '完全匹配',
                    widths: 80
                },
                {
                    props: 'notMatchCount',
                    labels: '无法匹配',
                    widths: 80
                },
                {
                    props: 'createByName',
                    labels: '创建人',
                    widths: 100
                },
                {
                    props: 'operator',
                    labels: '操作',
                    widths: 80,
                    slot: 'slot'
                }
            ],
            tableData: [],
            selected: [],
            showVisible: false,
            tableList: [],
            btnLoading: false,
            customerNo: ''
        }
    },
    computed: {
        noCertifiedFlag() {
            return !JSON.parse(window.localStorage.getItem('companyId'))
        }
    },
    mounted() {
        this.getListData()
    },
    activated() {
        this.getListData()
    },
    methods: {
        exportFile() {
            this.isBtnLoading = true
            let excelField = this.columns.map(e => e.props)
            let params = Object.assign({ data: this.formSearch }, { excelField }, {
                pageNum: 1,
                pageSize: 4000,
            })
            this.$post('/inquiryBase/exportList', params).then(({ data }) => {
                if (data.code === 'C0000') {
                    // 创建隐藏的可下载链接
                    var eleLink = document.createElement("a");
                    eleLink.download = "";
                    eleLink.target = "_blank";
                    eleLink.style.display = "none";
                    // 字符内容转变成blob地址
                    eleLink.href = data.message;
                    // 触发点击
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    // 然后移除
                    document.body.removeChild(eleLink);
                } else {
                    this.$message.error('导出失败！')
                }
            }).finally(() => {
                this.isBtnLoading = false
            })
        },
        exportFileSingle(row) {
            this.$get(`/inquiry-orders/${row.inquiryOrderNo}/details/export`, undefined, 'api').then(({ data }) => {
                try {
                    const { content, filename } = data.result;
                    download(content, filename);
                } catch (err) {
                    this.$message.error('导出失败');
                }
            })
        },
        cancelRow(row) {
            this.$confirm('确定要取消吗？', '提示', {
                type: 'warning'
            }).then(() => {
                this.$post('/inquiryBase/cancelInquiry', { id: row.id }).then(({ data }) => {
                    if (data.code === 'C0000') {
                        this.$message({
                            type: 'success',
                            message: '取消成功',
                            offset: 380,
                            grouping: true,
                        })
                        this.getListData()
                    }
                })
            }).catch(() => {
                // 取消loading
            })
        },
        statusColor(key) {
            let color = '#00458E'
            switch (key) {
                case '4':
                    color = 'red'
                    break;
                case '5':
                    color = 'red'
                    break;
                case '0':
                    color = '#FF6600'
                    break;
                case '6':
                    color = '#ccc'
                    break;
                default:
                    break;
            }
            return 'background-color: ' + color
        },
        downTempate() {
            window.open('https://scp-static.oss-cn-shenzhen.aliyuncs.com/prod/template/bom/BOM表模板.xlsx')
        },
        // 新增确认
        analyConfirm(inquiryDetails, value) {
            let params = Object.assign({ numberOfSets: value, customerNo: this.customerNo }, { inquiryDetails })
            this.btnLoading = true
            this.$post('/bom/match', params, 'api').then(({ data }) => {
                if (data.code === 'C0000') {
                    if (data.result) {
                        this.showVisible = false
                        let originData = data.result

                        // 筛选出状态为完全匹配或待确认的数据
                        const matchStateObj = {
                            ...data.result,
                            inquiryDetails: originData.inquiryDetails.filter(item => item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0)
                        }

                        // 价格接口参数
                        const paramsPrice = {
                            queryList: matchStateObj.inquiryDetails.map(o => {
                                return {
                                    mpn: o.bomData.selectedModelMpn,
                                    quantity: o.bomData.usageNum || '',
                                }
                            })
                        }

                        // 请求价格接口
                        this.$post('/products/price', paramsPrice, 'api').then(({ data }) => {
                            if (data.code === 'C0000') {
                                if (data.result) {
                                    const priceData = data.result
                                    this.$message.success(data.message)

                                    // 匹配结果中匹配状态为完全匹配或待确认的，将价格数据赋值给 matchResult.selectedModel.priceData，并与原本数据进行筛选
                                    originData.inquiryDetails.forEach(item => {
                                        if (item.matchResult.matchStatus === 1 || item.matchResult.matchStatus === 0) {
                                            item.matchResult.selectedModel = {
                                                ...item.matchResult.selectedModel,
                                                priceData: priceData.find(o => o.mpn === item.bomData.selectedModelMpn)
                                            }
                                        }
                                    })
                                    // 将数据保存到sessionStorage
                                    this.$utils.setSessionStorage('inquiryData', originData)
                                    // 跳转到详情页
                                    this.goDetail(JSON.parse(JSON.stringify(originData)))
                                } else {
                                    this.$message.error(data.message)
                                }
                            }
                        })
                    } else {
                        this.$message.error(data.message)
                    }
                }
            }).finally(() => {
                this.btnLoading = false
            })
        },
        beforeAvatarUpload(file) {
            const isLtSize = file.size / 1024 / 1024 < 5
            if (!isLtSize) {
                this.$message({
                    type: 'error',
                    message: file.name + '不能超过5MB!',
                    offset: 380,
                    grouping: true,
                })
                return false
            }
            let types = ['xlsx', 'xls'];
            var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
            if (types.indexOf(testmsg.toLowerCase()) === -1) {
                this.$message({
                    message: '仅支持 xlsx 和 xls 文件',
                    type: 'warning'
                })
                return false
            }
            this.isBtnLoading = true
            this.customerNo = file.name ? file.name.split('.')[0] : ''
        },
        uploadSuccess(res) {
            if (res.code === 'C0000') {
                this.tableList = res.result || []
                this.showVisible = true
            } else {
                this.$message.error(res.message)
            }
            this.isBtnLoading = false
        },
        handleSelectionChange(val) {
            this.selected = val
        },
        filterColumns(array) {
            this.columns = array;
        },
        pageSearch(obj) {
            this.page.currentPage = obj.currentPage;
            this.page.perPage = obj.perPage;
            this.getListData();
        },
        clearInquiryOrderNo() {
            this.formSearch.inquiryOrderNo = '';
            this.searchForm();
        },
        clearCustomerNo() {
            this.formSearch.customerNo = '';
            this.searchForm();
        },
        searchForm() {
            this.getListData()
        },
        //日期
        changeTimeRange(obj) {
            this.dateTimeRange = obj
            if (obj) {
                this.formSearch.createdAtStart = obj[0]
                this.formSearch.createdAtEnd = obj[1]
            } else {
                this.formSearch.createdAtStart = ''
                this.formSearch.createdAtEnd = ''
            }
            this.searchForm()
        },
        // 详情/编辑
        goDetail(result) {
            this.$router.push({
                path: '/inquire-detail',
                query: {
                    type: 'edit',
                    inquiryOrderNo: result.inquiryOrderNo,
                    cache: false
                }
            })
        },
        // 详情/编辑
        showDetail(row) {
            this.$router.push({
                path: '/inquire-detail',
                query: {
                    type: 'edit',
                    inquiryOrderNo: row.inquiryOrderNo,
                    cache: false
                }
            })
        },
        // 删除
        delClick(row) {
            this.$confirm('确定要删除吗，删除后无法恢复，请谨慎操作？', '提示', {
                type: 'warning',
            }).then(() => {
                this.$delete(`/inquiry-orders/${row.inquiryOrderNo}`, undefined, 'api').then(({ data }) => {
                    if (data.code === 'C0000') {
                        this.$message({
                            type: 'success',
                            message: '删除成功',
                            offset: 380,
                            grouping: true,
                        })
                        this.getListData()
                    }
                })
            }).catch(() => {
                // 取消loading
            })
        },
        // 列表数据
        getListData() {
            this.isloading = true

            // 列表参数
            let params = {
                ...this.formSearch,
                page: this.page.currentPage,
                perPage: this.page.perPage,
            }

            this.$get('/inquiry-orders', params, 'api').then(({ data }) => {
                if (data.code == 'C0000') {
                    this.tableData = data.result.data
                    this.page = data.result.pagination
                    this.page.total = data.result.pagination.total
                }
            }).finally(() => {
                this.isloading = false
            })
        },
        handleTabs(item) {
            this.activedName = item
            if (item) {
                this.formSearch.status = item
            } else {
                delete this.formSearch.statuss
                delete this.formSearch.status
            }

            this.getListData()
        },
        resetForm() {
            this.formSearch = {}
            this.dateTimeRange = ''
        }
    }
}
</script>

<style lang="scss">
.dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
}

.noClass {
    background: #FF6600;
}

.toConfirmClass {
    background: #FF8400;
}

.upload-inquire-list {
    .el-upload-list {
        margin: 0;
    }

    .el-upload-dragger {
        float: left;
        border: none;
        background-color: unset;
        padding: 0;
        margin-right: 20px;
    }

    .el-upload-list__item {
        transition: none !important;
        -webkit-transition: none !important;
        display: none !important;
    }

    .el-upload-list__item-name {
        transition: none !important;
        -webkit-transition: none !important;
    }
}
</style>
