<template>
<div class="ab-file-upload">
    <div class="upload">
        <el-popover
            placement="top-start"
            popper-class="form-pop"
            :width="300"
            trigger="hover"
            :disabled="!popover"
            :content="popover"
        >
            <template #reference>
                <slot name="title">
                    <h5>
                        <span v-for="(obj,idx) in params.items" :key="idx">
                            <span v-if="idx < 2">
                                {{obj.title}}<i v-if="obj.required">*</i><span v-if="idx == 0 && (params.items && params.items.length > 1)"> / </span>
                            </span>
                        </span>
                        <span v-if="params.items && params.items.length > 2">等</span>
                    </h5>
                </slot>
            </template>
        </el-popover>
        <div>
        <el-button @click="handlerUpload" v-if="!disabled">上传文件</el-button>
        <el-button @click="handerAllDownload" v-if="disabled" :loading="dowloading">一键下载</el-button>
        <div :class="{'displaybox': fileList && fileList.length}">
            <div v-for="(obj,idx) in fileList" :key="idx" class="mg-t-5" >
                <div >
                    <span class="tel span" @click="handerOreview(obj)">{{obj.disName}}</span>
                    <span class="operator">
                        <span @click="handerDownload(obj)">
                            <ab-svg name="my-upload" class="one"></ab-svg>
                        </span>
                        <span @click="handlerDelete(obj, idx)" v-if="!disabled">
                            <ab-svg name="my-off"></ab-svg>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        </div>
    </div>
    <el-dialog
        title="文件上传"
        v-model="fileUplpad.show"
        top="5vh"
        width="640px"
        class="file-upload-dialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        :close="handlerClose">
            <el-row class="file-view">
                <el-col :span="24" v-if="params.items && params.items.length > 1">
                    <el-radio-group v-model="attachType" @change="getList">
                        <el-radio :label="item.attachType" v-for="(item, idx) in params.items">{{item.title}}<i style="color:#ff2626" v-if="item.required">*</i></el-radio>
                    </el-radio-group>
                </el-col>
                <el-col :span="24">
                    <el-upload
                        class="upload-demo"
                        drag
                        action
                        :before-upload="beforeUpload"
                        :multiple="multiple"
                    >

                        <span class="upload-icon">
                            <ab-svg name="upload-file"></ab-svg>
                        </span>
                        <div class="el-upload__text">
                        点击 <em>上传文件</em>，或将文件拖到此窗口即可上传！
                        </div>
                    </el-upload>
                </el-col>
                <el-col :span="24">
                    <div class="file-item" v-for="(obj,idx) in fileData[attachType]">
                        <el-row>
                            <el-col :span="12">
                                <div class="tel"  @click="handerOreview(obj)">{{obj.disName}}</div>
                            </el-col>
                            <el-col :span="12">
                                <div class="reference">
                                    <span @click="handerDownload(obj)">
                                        <ab-svg name="my-upload" class="one"></ab-svg>
                                    </span>
                                    <span @click="handlerDelete(obj, idx)">
                                        <ab-svg name="my-off"></ab-svg>
                                    </span>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handlerClose">取消</el-button>
                <el-button type="primary" :loading="btnLoading" @click="handlerClose">确定</el-button>
            </span>
        </template>
    </el-dialog>
    <ab-preview :preview="preview"></ab-preview>
</div>
</template>

<script>
export default {
    name: 'ab-upload',
    data () {
        return {
            fileUplpad: {
                show: false
            },
            attachType: '',
            isloading: false,
            dowloading: false,
            fileData: {},
            fileList: [],
            preview: {
                show: false
            },
        }
    },
    props: {
        params: {
            type: Object,
            default: {}
        },
        multiple: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        popover: {
            type: String,
            default: ''
        }
    },
    created() {

    },
    mounted() {
        this.getList()
    },
    watch: {
        params: {
            handler: function(val, oldval) {
                this.getList()
            },
            deep: true
        },
        'fileUplpad.show': {
            handler: function(val, oldval) {
                if (val) {
                    document.addEventListener('paste', this.filePaste)
                } else {
                    document.removeEventListener('paste', this.filePaste)
                }
            },
            deep: true
        }
    },
    methods: {
        filePaste (event) {
            let data = (event.clipboardData || window.clipboardData)
            let file = data.items[0].getAsFile()
            this.beforeUpload(file)
        },
        resetData () {
            this.fileData = {}
            this.fileList = []
        },
        handlerClose () {
            this.fileUplpad.show = false
        },
        handlerUpload () {
            this.attachType = this.params.items[0].attachType
            this.getList()
            if (!this.params.sourceBillId) {
                this.$get('/p/system/genId').then((res) => {
                    this.params.sourceBillId = res.data.result
                    this.$emit('update-billsId', res.data.result)
                    this.fileUplpad.show = true
                })
            } else {
                this.fileUplpad.show = true
            }
        },
        beforeUpload (file) {
            let fd = new FormData()
            let getTime = new Date().getTime() + '-'
            let item = { _$index: getTime, percentage: 0, uploadStatus: undefined }
            fd.append('file', file)
            fd.append('v', getTime)
            fd.append('attachType', this.attachType)
            fd.append('coreBillIds', this.params.coreBillIds || '')
            fd.append('coreBillNumbers', this.params.coreBillNumbers || '')
            fd.append('sourceBillId', this.params.sourceBillId)
            fd.append('sourceBillNumber', this.params.sourceBillNumber || '')
            fd.append('sourceBillType', this.params.sourceBillType)
            this.upload(fd, item)
                .then((res) => {
                if (res.data && res.data.code === 'C0000') {
                    this.$message({
                        type: 'success',
                        message: '上传成功！',
                        appendTo: '.upload-demo',
                        grouping: true,
                        offset: 160
                    })
                    this.$emit('fileList')
                    this.getList()
                }
                })
                .catch((res) => {
                if (res && res.data) {

                }
            }).finally(() => {
                setTimeout(() => {

                }, 1000)
            })
            return false
        },
        upload(data, item) {
            return this.$http({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: this.$adornUrl('/api/attactment/uploadFile'),
                timeout: 20000 * 60,
                data: data,
                onUploadProgress: function (progress) {
                }
            })
        },
        getList() {
            if (!this.params.sourceBillId) {
                this.fileData = this.setDataAssign({})
                this.fileList = []
                this.params.items.map(e => {
                    if (this.fileData[e.attachType]) {
                        this.fileList = this.fileList.concat(this.fileData[e.attachType])
                    }
                })
                return false
            }
            let params = {
                sourceBillType: this.params.sourceBillType,
            }
            if(this.params.sourceBillIdList && this.params.sourceBillIdList.length) {
                params.sourceBillIdList = this.params.sourceBillIdList
            }else {
                params.sourceBillId = this.params.sourceBillId
            }
            this.isloading = true
            this.$post("/api/attactment/list", params)
            .then(res => {
                this.fileData = this.setDataAssign(res.data.result || {})
                this.fileList = []
                this.params.items.map(e => {
                    if (this.fileData[e.attachType]) {
                        this.fileList = this.fileList.concat(this.fileData[e.attachType])
                    }
                })
            })
            .finally(e => {
                setTimeout(() => {
                    this.isloading = false
                })
            }, 500)
        },
        setDataAssign(obj) {
            if (this.params.items) {
                this.params.items.forEach(e => {
                    if (!obj[e.attachType]) {
                        obj[e.attachType] = []
                    }
                    if(this.params.initAttachMap) {
                        if(this.params.initAttachMap[e.attachType]) {
                            if(!obj[e.attachType].length) {
                                obj[e.attachType] = this.params.initAttachMap[e.attachType].filter(e => !e.isDeleted)
                            }else{
                                let arr = []
                                this.params.initAttachMap[e.attachType].forEach(e=>{
                                    if(!(obj[e.attachType].some(el=>el.id==e.id)) && !e.isDeleted) {
                                        arr.push(e)
                                    }
                                })
                               obj[e.attachType] = obj[e.attachType].concat(arr)
                            }
                        }
                    }
                })
            }
            return obj
        },
        handerDownload (obj) {
          let eleLink = document.createElement('a')
          eleLink.download = ''
          eleLink.style.display = 'none'
          eleLink.href = obj.fileUrl
          document.body.appendChild(eleLink)
          eleLink.click()
          document.body.removeChild(eleLink)
        },
        handlerDelete (obj, index) {
            this.$confirm("确定要删除吗，删除后无法恢复，请谨慎操作？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.isloading = true
                var id = obj.id
                if(this.params.initAttachMap) {
                    for (const key in this.params.initAttachMap) {
                        if (this.params.initAttachMap.hasOwnProperty.call(this.params.initAttachMap, key)) {
                            const element = this.params.initAttachMap[key];
                            if(obj.attachType == key) {
                                element.forEach(e=>{
                                    if(e.id == obj.id) {
                                        e.isDeleted = 1
                                    }
                                })
                            }
                        }
                    }
                }
                this.$post('/oms/billAttach/del', {ids: [id]}).then(res => {
                    if (res.data && res.data.code == 'C0000') {

                        this.$message({
                            type: "success",
                            message: "删除成功！",
                            grouping: true,
                            offset: 160
                        })
                        this.fileList.splice(index, 1)
                        this.getList()
                    }
                })
            }).finally(e => {
                this.isloading = false
            })
        },
        handerAllDownload () {
            this.dowloading = true
            this.$post('/oms/billAttach/compress',{listData: this.fileList}).then(({data}) => {
                if (data && data.code == 'C0000') {
                    this.$message({
                        type: "success",
                        grouping: true,
                        message: "打包下载成功！",
                        offset: 380
                    })
                    let eleLink = document.createElement("a")
                    eleLink.download = ""
                    eleLink.style.display = "none"
                    // 字符内容转变成blob地址
                    eleLink.href = data.result
                    // 触发点击
                    document.body.appendChild(eleLink)
                    eleLink.click()
                    // 然后移除
                    document.body.removeChild(eleLink)
                }
            }).finally(() => {
                this.dowloading = false
            })
        },
        handerOreview(obj) {
            this.preview.fileUrl = obj.fileUrl || obj.viewFileUrl
            this.preview.show = true
        },
        validate () {
            return new Promise((resolve, reject) => {
                let errMsg = ''
                for (let i = 0; i < this.params.items.length; i++) {
                    if (this.params.items[i].required) {
                        if (!this.fileData[this.params.items[i].attachType] || this.fileData[this.params.items[i].attachType].length == 0) {
                            if (this.params.items[i].message) {
                                errMsg = this.params.items[i].message
                            } else {
                                errMsg = `请上传${this.params.items[i].title}`
                            }
                            break
                        }
                    }
                }
                if (errMsg == '') {
                    resolve(true)
                } else {
                    reject(
                        this.$message({
                            type: 'error',
                            message: errMsg,
                            grouping: true,
                            offset: 380
                        })
                    )

                }
            })
        }

    }
}
</script>

<style lang="scss">
.ab-file-upload{
.upload {
    padding: 17px 0px;
    h5 {
        margin-bottom: 10px;
        font-size: 14px;
    }
    i {
        color: #ff2626;
    }
    & > div {
        display: flex;
        justify-content: space-between;
        .displaybox {
            width: 100%;
            min-height: 45px;
            padding: 0 5px 5px 5px;
            margin-left: 20px;
            background-color: #f0f2f5;
            border-radius: 6px;
            div {
            display: inline-block;
            padding: 0 0 0 15px;
            margin-right: 7px;
            height: 35px;
            line-height: 35px;
            background-color: #fff;
            border-radius: 8px;
            .span {
                margin-right: 30px;
            }
            .icon-svg {
                width: 19px;
                height: 19px;
                vertical-align: middle;
                cursor: pointer;
            }
            .one {
                margin-right: 7px;
            }
            }
            .mg-t-5 {
                cursor: pointer;
            }
            .operator {
                display: none;
            }
            .mg-t-5:hover{
                 .operator{
                    display: inline-block;
                }
            }
        }
    }
    .tel {
        cursor: pointer;
        &:hover{
            text-decoration: underline;
            color: #0B7AFF;
        }
    }
}
.file-view {
    text-align: center;

}
.file-upload-dialog {
    .el-dialog__body {
        padding-top: 10px;
        margin: 0 20px;
    }
    .upload-demo {
        margin-top: 10px;
    }
    .el-upload-dragger {
        background-color: #ffffff;
        border: none;
    }
    .file-item {
        background-color: #F0F2F5;
        font-size: 14px;
        height: 35px;
        line-height: 35px;
        border-radius: 8px;
        margin-bottom: 10px;
    }
    .tel {
        text-align: left;
        margin-left: 17px;
        cursor: pointer;
        &:hover{
            text-decoration: underline;
            color: #0B7AFF;
        }
    }

    .reference {
        text-align: right;
        margin-right: 17px;
        .icon-svg {
            width: 19px;
            height: 19px;
            margin-left: 7px;
            vertical-align: text-top;
            cursor: pointer;
        }
    }
}
.mg-t-5 {
    margin-top: 5px;
}
.upload-icon {
    .icon-svg {
        width: 85px;
        height: 64px;
        margin-bottom: 10px;
    }
}
}
</style>
