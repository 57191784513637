<template>
  <div class="import-orders-detail" v-loading="orderLoading">
    <div class="orderStatus" v-if="dataForm.orderstatus == 1">
      待付款
    </div>
    <ab-heads v-if="routeQuery.type == 'view'" :numbers="dataForm.orderNo" :createTime="dataForm.createTime"></ab-heads>
    <el-form :model="dataForm" ref="dataForm" label-position="top" class="dataForm" :rules="rules">
      <div class="order-step">
        <div class="el-steps el-steps--horizontal">
          <div class="el-step is-horizontal is-center" style="flex-basis: 25%;" v-for="(obj, idx) in orderTrack"
            :key="idx">
            <div class="el-step__head " :class="{ 'is-finish': (obj.status || obj.createDate) && !obj.isClose }">
              <div class="el-step__line"></div>
              <div class="ab-icon is-text">
                <div class="el-step__icon-inner">
                  <ab-svg :name="obj.icon"></ab-svg>
                </div>
              </div>
            </div>
            <div class="el-step__main">
              <div class="el-step__title" :class="{ 'is-finish': (obj.status || obj.createDate) && !obj.isClose }">
                {{ obj.name }} <span v-if="obj.isClose && obj.key == 'wait_pay_exchange'">(已关闭)</span></div>
              <div class="el-step__description" :class="{ 'is-finish': (obj.status || obj.createDate) && !obj.isClose }">
                {{ obj.createDate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contentInfo">
        <import-order-detail :routeQuery="routeQuery" :data="orderData.inquiryDetails" v-if="isAdd"></import-order-detail>
        <order-detail-view :routeQuery="routeQuery" v-if="isView"></order-detail-view>
      </div>
    </el-form>
  </div>
</template>
<script>
import importOrderDetail from './import-order-detail'
import orderDetailView from './order-detail-view'
export default {
  name: 'order-detail',
  components: {
    importOrderDetail,
    orderDetailView
  },
  data() {
    return {
      routeQuery: {
        list: []
      },
      goodsInfoData: {},
      activeIndex: 0,
      expressOptions: [],
      expressTypeOptions: [],
      logvisible: false,
      orderstatus: 1,
      orderLoading: false,
      orderTrack: [
        {
          status: 1,
          name: '下单',
          createDate: '',
          icon: 'xiadan',
          key: "createTime"
        },
        {
          status: 0,
          name: '付款',
          createDate: '',
          icon: 'fukuan',
          key: "payTime"
        },
        {
          status: 0,
          name: '发货',
          createDate: '',
          icon: 'fahuo',
          key: "sendTime"
        },
        {
          status: 0,
          name: '收货',
          createDate: '',
          icon: 'shouhuo',
          key: "takeTime"
        },

        {
          status: 0,
          name: '完成',
          createDate: '',
          icon: 'wancheng',
          key: "completeTime"
        }
      ],
      viewStep: 0,
      dataForm: {
        entry: [],
      },
      orderData: {
        inquiryDetails: [] // 下单的数据
      }
    }
  },
  computed: {
    isAdd () {
      if(this.routeQuery.type === 'add') {
        return true
      } else {
        return false
      }
    },
    isView () {
      if (this.routeQuery.type === 'view') {
        return true
      } else {
        return false
      }
    }
  },
  activated() {
    let query = this.$route.query || {}
    this.routeQuery = Object.keys(query).length ? query : this.routeQuery
    if (
      typeof this.routeQuery.cache === "string"
        ? JSON.parse(this.routeQuery.cache)
        : this.routeQuery.cache
    ) {
      return false
    } else {
      this.routeQuery.cache = true
    }
    this.goodsInfoData.type = this.routeQuery.type
    if (this.routeQuery.type == 'view') {
      this.getInitData()
    }else{
      this.orderTrack = [
        {
          status: 1,
          name: '下单',
          createDate: '',
          icon: 'order-tijiao',
          key: "createTime"
        },
        {
          status: 0,
          name: '付款',
          createDate: '',
          icon: 'fufei',
          key: "payTime"
        },
        {
          status: 0,
          name: '发货',
          createDate: '',
          icon: 'cangchu',
          key: "sendTime"
        },
        {
          status: 0,
          name: '收货',
          createDate: '',
          icon: 'cangchu',
          key: "completeTime"
        },

        {
          status: 0,
          name: '完成',
          createDate: '',
          icon: 'wancheng',
          key: "completeTime"
        }
      ]
    }
  },
  mounted() {
    this.routeQuery = this.$route.query
    this.goodsInfoData.type = this.routeQuery.type
    if (this.routeQuery.type == 'view') {
      this.getInitData()
    }else{
      this.orderTrack =[
        {
          status: 1,
          name: '下单',
          createDate: '',
          icon: 'order-tijiao',
          key: "createTime"
        },
        {
          status: 0,
          name: '付款',
          createDate: '',
          icon: 'fufei',
          key: "payTime"
        },
        {
          status: 0,
          name: '发货',
          createDate: '',
          icon: 'cangchu',
          key: "sendTime"
        },
        {
          status: 0,
          name: '收货',
          createDate: '',
          icon: 'cangchu',
          key: "takeTime"
        },

        {
          status: 0,
          name: '完成',
          createDate: '',
          icon: 'wancheng',
          key: "completeTime"
        }
      ]
      this.orderData.inquiryDetails = JSON.parse(this.routeQuery.inquiryDetails) || [];
    }
  },
  methods: {
    getInitData() {
      if (this.routeQuery.orderNo) { // 编辑/详情
        this.$get(`/orders/${this.routeQuery.orderNo}`, {},'api').then(({ data }) => {
          if (data.code === 'C0000') {
            this.dataForm = data.result
            this.orderTrack = this.orderTrack.map(item => {
              if (item.key && this.dataForm[item.key]) {
                item.createDate = this.dataForm[item.key]
                item.status = 1
              }
              return item
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.el-step.is-center .el-step__description {
  padding-left: 0  !important;
  padding-right: 0 !important
}

.import-orders-detail {
  position: relative;
  padding: 40px;

  .expressDiv {
    ul {
      display: flex;

      li {
        width: 150px;
        height: 45px;
        background: #EBF7FC;
        border: 1px solid #F0F2F5;
        border-radius: 8px;
        text-align: center;
        line-height: 42px;
        margin-left: 20px;
        cursor: pointer;
        position: relative;

        &:first-child {
          margin: 0;
        }
      }

      .activeLi {
        border: 1px solid #00458E;
      }
    }

    .icon-div {
      width: 0;
      height: 0;
      border-bottom: 30px solid #00458E;
      border-left: 30px solid transparent;
      position: absolute;
      right: 0;
      bottom: 0;
      border-radius: 0 0 8px 0;

      .el-icon {
        position: absolute;
        right: 1px;
        color: #fff;
        bottom: -27px;
      }
    }
  }

  .order-step {
    padding: 30px;
    margin: 0 8%;

    .ab-icon {
      .el-step__icon-inner {
        padding: 13px 16px;
        height: 64px;
        width: 64px;
        background-color: var(--el-color-primary-light-9);
        border-radius: 8px;
      }

      .icon-svg {
        width: 33px;
        height: 36px;
        vertical-align: middle;
      }
    }

    .el-step__line {
      left: 55%;
      right: -41%;
      margin: 20px;
      background: none;
      width: 100%;
      height: 2px;
      background-image: linear-gradient(to right, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 50%, transparent 50%);
      background-size: 15px 1px;
    }

    .el-step__title {

      font-weight: 500;
    }

  }

  .contentInfo {
    h3 {
      font-size: 18px
    }

    .title {
      display: flex;
      justify-content: space-between;
      padding: 0px 0 20px 0;

      h3 {
        color: #000000;
        line-height: 40px;
      }

      .progress {
        height: 45px;
        border: 1px solid #C4C6CF;
        cursor: pointer;
        border-radius: 22.5px;
        line-height: 45px;
        padding: 0 20px;

        .text {
          font-size: 14px;
          color: #111111;
        }
      }
    }
  }

  .logisticsInfo {
    padding-bottom: 10px;


    .el-col.el-col-8 {
      margin-bottom: 20px;
    }

    .supplierName {
      width: 100%;
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #f0f2f5;
      line-height: 24px;

      .supplier {
        padding: 5px 12px;
        height: 24px;
        margin-right: 20px;
        line-height: 14px;
        border-radius: 4px;
        background-color: #f0f2f5;

        span {
          color: #fe2424;
        }
      }

      .icon-svg {
        width: 24px;
        height: 24px;
        vertical-align: middle;
        margin: 0 10px;
        cursor: pointer;
      }

      p {
        font-size: 22px;
        font-weight: bold;
      }
    }

    .receivingAddress {
      font-size: 14px;
      font-weight: 400;
      margin: 18px 0 17px 0;

      .icon-svg {
        width: 11px;
        height: 16px;
        vertical-align: bottom;
      }
    }

    .el-alert {
      margin: 32px 0 20px;
    }

    .remark {
      margin-top: 32px;

      .el-textarea {
        .el-textarea__inner {
          min-height: 45px !important;
          padding-top: 11px;
          box-shadow: 0 0 0 1px transparent;

          &:focus {
            box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset;
          }
        }
      }
    }
  }

  .goodsInfo {
    margin-bottom: 30px;

    >h3 {
      margin-bottom: 37px;
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 25px;
      justify-content: space-between;

      h3 {
        margin: 0;
      }
    }

    .materialTable {
      margin-bottom: 36px;
    }

    .el-row {
      .el-col.el-col-6 {
        span {
          font-size: 14px;
          color: #636871;
        }

        div {
          font-size: 30px;
          font-weight: 500;
          margin-top: 20px;
        }
      }
    }
  }
}

.orderStatus {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 0.96rem;
  height: 0.34rem;
  line-height: .3rem;
  background: linear-gradient(34deg, #FFE42B, #FFE42B);
  border-radius: 0 0 0.18rem 0.18rem;
  font-size: .14rem;
  text-align: center;
  cursor: pointer;
}</style>
