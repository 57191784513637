<template>
    <div class="content-layout">
        <el-container>
            <el-header class="main-heard">
                <el-row>
                    <el-col :span="12">
                        <div class="nav-top-logo">
                            <img src="@/assets/img/logo.png" />
                        </div>

                    </el-col>
                    <el-col :span="12" class="nav-right">

                        <ul>
                            <el-badge :value="messageTotal" class="item top-menu" :hidden="messageTotal == 0">
                                <li @click="kefuShow = true"><ab-svg name="kefu"></ab-svg>客服</li>
                                <li @click="msgDrawer.show = true">
                                    <ab-svg name="xiaoxi"></ab-svg>
                                    <el-badge :value="messageTotal" class="item" :hidden="messageTotal == 0">
                                        消息
                                    </el-badge>
                                </li>
                            </el-badge>
                            <li>
                                <div class="user-info">
                                    <el-dropdown @command="handleUserCommand" placement="bottom-end" trigger="click">
                                        <span class="el-dropdown-link">
                                            <div class="user-name">
                                                <img :src="currentAvatar ? currentAvatar : require('../../assets/img/logo-user.png')" />
                                            </div>
                                        </span>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item command="1">
                                                    <i style="line-height: 12px;"><ab-svg name="user"
                                                            style="width: 14px; height: 14px;"></ab-svg></i>
                                                    个人中心
                                                </el-dropdown-item>
                                                <el-dropdown-item command="2">
                                                    <i style="line-height: 12px;"><ab-svg name="switchButton"
                                                            style="width: 14px; height: 14px"></ab-svg></i>
                                                    退出登陆
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </div>
                            </li>
                        </ul>

                    </el-col>
                </el-row>
            </el-header>
            <el-container class="content">
                <el-aside :class="{ 'main-left': true, 'isHidden': sidebarFold }">
                    <mainMenu ref="menu"></mainMenu>
                </el-aside>
                <el-main class="main-content">
                    <el-tabs type="card" v-model="mainTabsActiveName" class="nav-tab actived-manue"
                        @tab-click="handleClick">
                        <el-tab-pane :label="item.name" :name="item.index" v-for="(item, idx) in mainTabs" :key="idx">
                            <template #label>
                                {{ item.name }}
                                <span v-if="item.name != '首页'" @click="handlerRemove(item.index)">
                                    <ab-svg name="tab-close"></ab-svg>
                                </span>
                            </template>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="tankage" :style="{ 'height': (documentClientHeight - 130) + 'px' }">
                        <div class="tankage-main">
                            <router-view v-slot="{ Component }">
                                <keep-alive :include="keepAliveView">
                                    <component ref="keepView" :is="Component"
                                        :class="{ 'ab-content-details': $route.meta.keepAlive }" :key="$route.index" />
                                </keep-alive>
                            </router-view>
                        </div>
                    </div>
                </el-main>

            </el-container>
        </el-container>
        <div class="sidebarfold" v-if="isfold" @click="handlerFold">
            <ab-svg name="zhedie"></ab-svg>
        </div>
        <message :drawer="msgDrawer" @mesList="mesList"></message>
        <el-drawer v-model="kefuShow" title="咨询客服">
            <div class="kefu-body">
                <img src="@/assets/img/qc.png" />
                <p>微信扫描 “二维码” ，添加客服微信进行咨询</p>
                <p> <span class="left"><ab-svg style="color: #000" name="phone1"></ab-svg> 13724395011</span>&nbsp;&nbsp;&nbsp; <span class="right"> <ab-svg name="email1"></ab-svg> &nbsp;peidanxiong6919@163.ccom</span> </p>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import mainMenu from './main-menu.vue'
import message from './message.vue'
import ws from "../../utils/ws";
import { h } from 'vue'
export default {
    components: {
        mainMenu,
        message
    },
    data() {
        return {
            willExpireDialogVisible: false,
            couponDialog: {
                show: false,
                couponList: []
            },
            mesList: [],
            disCouDialog: false, // 折扣券弹框
            config: {
                disabled: ["google", "facebook", "twitter", "tencent"],
                url: this.shareLink,
                image: "",
                wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
                wechatQrcodeHelper:
                    "<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>"
            },
            noticeList: {},
            value: '',
            routeQuery: {},
            bizType: {
                clientImportRole: false,
                clientExportRole: false,
                clientWmsRole: false,
                clientSalesRole: false,
                activeIndex: this.activeIndexMenu
            },
            ownOrgArry: [],
            ownOrgIds: [],
            mainOrgId: '',
            isReload: true,
            activeName: 'first',//消息
            drawer: false,//消息抽屉
            drawer2: false,//公告抽屉
            innerDrawer: false,
            organizerDialog: {
                show: false,
                idx: ''
            },
            service: {
                show: false
            },
            msgDrawer: {
                show: false
            },
            dataForm: {},
            attachUrl: '',
            companyData: {},
            //消息数量
            isHelp: true,
            //签约后客服信息
            customerInfo: {},
            companyName: "",
            kefuShow: false
            // messageList: this.$store.state.common.messageList
        }
    },
    computed: {
        messageList: {
            get() {
                return this.$store.state.messageList
            },
            set(val) {
                this.$store.commit('SET_MESSAGE_LIST', val)
            }
        },
        messageTotal: {
            get() {
                return this.$store.state.messageTotal
            },
            set(val) {
                this.$store.commit('SET_MESSAGE_TOTAL', val)
            }
        },
        activeIndexMenu: {
            get() {
                return (
                    this.$store.state.activeIndexMenu
                )
            },
            set(val) {
                this.$store.commit("activeIndex", val)
            }
        },
        mainTabsActiveName: {
            get() { return this.$store.state.mainTabsActiveName },
            set(val) { this.$store.commit('updateMainTabsActiveName', val) }
        },
        mainTabs: {
            get() { return this.$store.state.mainTabs },
            set(val) { this.$store.commit('updateMainTabs', val) }
        },
        sidebarFold: {
            get() {
                return this.$store.state.sidebarFold
            },
            set(val) {
                this.$store.commit('updateSidebarFold', val)
            }
        },
        isfold() {
            return this.sidebarFold
        },
        keepAliveView: {
            get() { return this.$store.state.keepAliveView },
            set(val) { this.$store.commit('updateKeepAliveView', val) }
        },
        documentClientHeight: {
            get() {
                return this.$store.state.documentClientHeight;
            },
            set(val) {
                this.$store.commit('updateDocumentClientHeight', val);
            }
        },
        currentAvatar: {
            get() { return this.$store.state.currentAvatar },
            set(val) { this.$store.commit('updateCurrentAvatar', val) }
        },
    },
    created() {
        this.sidebarFold = false
        this.getMessageList()
        // this.showNotice({title: '测试测试测试', content:'吃饭了没吃饭了没吃饭了没吃饭了没吃饭了没', })
        ws.init()
        ws.showNotice = this.showNotice
        ws.closeNotice = this.closeNotice
        ws.getMessageList = this.getMessageList
    },
    mounted() {
        this.sidebarFold = false
        this.documentClientHeight = document.documentElement['clientHeight']
        window.onresize = () => {
            this.documentClientHeight = document.documentElement['clientHeight']
        }
    },
    watch: {
        $route(to, from) {
            if(from.path == '/my-enterprise' || to.path == '/') {
                this.getUserInfo()
            }
            this.routeHandle(to)
        },
        'mainTabsActiveName': {
            handler: function(val, oldval) {
                this.sidebarFold = val.indexOf('/inquire-detail') != -1 || val.indexOf('/order-detail') != -1 || val.indexOf('/invoice-detail') != -1
            },
            deep: true
        }
    },
    methods: {
        noticeReaded(event) {
            const id = event.target.getAttribute('id')
            this.$post('/message/msgInmail/consumeMsg', [id]).then(({data}) => {
                if(data.code === 'C0000') {
                    this.closeNotice(id)
                    this.getMessageList()
                }
            })
        },
        noticeDetail(data) {
            let path = 'order-detail'
            if(data.typeId == 'INQUIRY') {
                path = '/inquire-detail'
            }else if(data.typeId == 'INVOICING' || data.typeId == 'INVOICED') {
                path = '/invoice-detail'
            }
            this.$router.push({
                path,
                query: {
                    type: 'view',
                    cache: false,
                    id: data.billIdStr
                }
            })
            this.$post('/message/msgInmail/consumeMsg', [data.idStr]).then(({data}) => {
                if(data.code === 'C0000') {
                    this.closeNotice(id)
                    this.getMessageList()
                }
            })
        },
        getMessageList() {
           let params = {
            page: 0, pageSize: 20,isRead: 'isUnread'
           }
            this.$get("/notifications", params,'api').then((res) => {
                if (res.data && res.data.code === "C0000") {
                    this.messageList = res.data.result.list.data
                    this.messageTotal = res.data.result.unreadCount
                } else {
                this.$message.error(res.data.message);
                }
            });
        },
        showNotice(data) {
            this.getMessageList()
            if (data.id && this.noticeList[data.id]) {
                return false
            }
            // const h = this.$createElement;
            this.noticeList[data.id] = this.$notify({
                dangerouslyUseHTMLString: true,
                iconClass: 'm-icon',
                customClass: 'custom-message',
                duration: 20000,
                position: 'top-right',
                title: data.title,
                onClose: () => {
                if (data.id) {
                    delete this.noticeList[data.id]
                }
                },
                message: h('div', null, [
                h(
                    'div',
                    { class: 'notify-text', style: 'word-break: break-all;' },
                    data.content
                ),
                h('div', { class: 'notify-button' }, [
                    h(
                    'button',
                    {
                        onClick: (e) => {
                        this.noticeReaded(e)
                        },
                        class: 'el-button el-button--default el-button--small',
                        id: data.id
                    },
                    '忽略'
                    ),
                    h(
                    'button',
                    {
                        onClick: () => {
                        this.noticeDetail(data)
                        },
                        class:
                        'el-button el-button--default el-button--small el-button--primary',
                        id: data.id,
                        url: data.msgUrl,
                        typeName: data.typeName,
                        billIdStr: data.billIdStr
                    },
                    '详情'
                    )
                ])
                ])
            });
        },
        // 关闭消息框
        closeNotice(id) {
            if (id && !isNaN(id)) {
                if (this.noticeList[id]) {this.noticeList[id].close();
          delete this.noticeList[id]}
            } else {
                for (let i in this.noticeList) {
                this.noticeList[i].close();
          delete this.noticeList[i]
                }
            }
        },
        handlerFold() {
            this.sidebarFold = !this.sidebarFold
        },
        handleClick() {
            setTimeout(() => {
                this.$router.push({ path: this.mainTabsActiveName })
                this.activeMenu = this.mainTabsActiveName
            }, 50)
        },
        handlerRemove(val) {
            setTimeout(() => {
                if (val != '/') {
                    let tab = this.mainTabs.filter(item => item.index !== this.mainTabsActiveName)
                    this.mainTabs = tab
                    this.mainTabsActiveName = tab[tab.length - 1].index
                    this.activeMenu = this.mainTabsActiveName
                    this.$router.push({ path: this.activeMenu })
                }
            }, 50)
        },
        routeHandle(route) {
            setTimeout(() => {
                if (route.meta && route.meta.keepAlive) {
                    this.isHelp = false
                } else {
                    this.isHelp = true
                }
                if (route.path == '/') {
                    this.activeIndexMenu = '00'
                } else if (route.path == '/my-list') {
                    this.activeIndexMenu = '06'
                } else {
                    this.activeIndexMenu = route.meta.type
                }
                let tab = this.mainTabs.filter(item => item.name === route.name)[0]
                if (!tab) {
                    tab = {
                        menuId: route.meta.menuId || route.name,
                        name: route.name,
                        title: route.meta.title,
                        index: route.path,
                        keepAlive: route.meta.keepAlive
                    }
                    this.mainTabs = this.mainTabs.concat(tab)
                }

                this.mainTabsActiveName = tab.index
                let viewTemp = []
                this.mainTabs.map(e => {
                    let view = e.index.replace('/', '')
                    if (view) {
                        viewTemp.push(view)
                    }
                })
                this.keepAliveView = viewTemp
            }, 50)
        },
        handleUserCommand(val) {
            if (val == 1) {
                this.$router.push('/my-center')
            } else if (val == 2) {
                this.$confirm(`确定进行[退出]操作?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$delete("/auth/logout").then(({ data }) => {
                        if (data && data.code === "C0000") {
                            this.$utils.clearLoginInfo()
                            this.$utils.clearAllSessionStorage();
                            window.location.href = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/#/login?v=' + new Date().getTime()
                            window.location.reload(true)
                        }
                    })
                }).catch((e) => {
                    console.log(e)
                })
            }

        },
        getUserInfo() {
            this.companyName = ''
            this.$post('/company/getByUser').then(({data}) => {
                if(data.code === 'C0000') {
                    if(data.result) {
                        this.companyName = data.result.name || ''
                    }
                }
            })
        }
    }

}
</script>

<style lang="scss">
.content-layout {
    background: linear-gradient(1deg, #c2c5c7, #fcfeff);
    box-shadow: 0px 3px 68px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: top, bottom;
    padding-bottom: 20px;

    .customer {
        .el-drawer {
            padding: 0 20px
        }

        //客服
        .customerService {
            width: 420px;
            padding-top: 40px;
            text-align: center;

            .img {
                width: 120px;
                height: 120px;
                margin: 0 auto;
                border-radius: 50%;

                img {
                    width: 100%;
                }
            }

            .info {
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                color: #000000;

                .phone {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 12px;
                        margin-right: 10px;
                    }
                }

                .email {
                    display: flex;
                    align-items: center;

                    svg {
                        width: 15px;
                        margin-right: 10px;
                        color: #A1A4A7;
                    }
                }
            }

            .name {
                background-color: #F0F2F5;
                color: #F87243;
                display: inline-block;
                border-radius: 4px;
                padding: 4px 16px;
                font-size: 16px;
                font-weight: bold;
                margin: 15px 0 20px;
            }

            .address {
                display: flex;
                margin-top: 15px;
                font-size: 16px;
                text-align: left;

                svg {
                    width: 15px;
                    height: 24px;
                    margin-right: 10px;
                }
            }
        }
    }

    .main-left {
        width: 194px;
    }

    .isHidden {
        width: 0;
        height: 0;
    }

    .main-heard {
        position: relative;
        --el-header-height: 80px;
        .top-menu{
            li{
                float: left;
            }
        }
        .nav-top-logo {
            margin-left: 10px;

            img {
                vertical-align: middle;
            }
        }

        .nav-conter {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;

            img {
                width: 146px;
                height: 31px;
            }

            .ab-select {
                height: 28px;
                margin-left: 15px;
                line-height: 28px;
                border: none;
                background-color: #E7F0FD;
                border-radius: 8px;
                padding: 0 10px;
                font-size: 16px;
                cursor: pointer;

                option {
                    cursor: pointer;
                }

                .icon-svg {
                    margin-left: 5px;
                    vertical-align: middle;
                }

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .el-menu {
            border-right: none;
            background: none;
            border-bottom: none;
        }

        .el-menu-item {
            font-size: 16px;
            margin-right: 20px;
        }

        .icon-svg {
            height: 16px;
            width: 16px;
            margin-right: 5px;
        }

        .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
        .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
            background-color: transparent;
        }

        .el-menu--horizontal>.el-menu-item.is-active {
            background-image: url(@/assets/img/nav-active.png);
            background-repeat: no-repeat;
            background-position: bottom;
            font-weight: bold;
            // border-bottom:none;
        }

        .nav-right {
            text-align: right;
            position: relative;

            ul {
                display: flex;
                flex-wrap: nowrap;
                justify-content: right;
                margin-top: 19px;
                margin-right: -5px;

                li {
                    width: 47px;
                    margin-left: 40px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, 'Noto Sans SC', Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                    cursor: pointer;

                    .icon-svg {
                        width: 14px;
                        height: 14px;
                        vertical-align: middle;
                    }
                }
            }

            .user-info {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 10px;
                padding-right: 10px;
                z-index: 1;

                .user-name {
                    // background-color: #1F77F4;
                    color: #ffffff;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    overflow: hidden;
                    // padding-top: 10px;
                    text-align: center;
                    object-fit: contain;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

            }

            .system-change {
                width: 80px;
                height: 60px;
                position: absolute;
                cursor: pointer;
                right: 430px;

                .icon-svg {
                    width: 100%;
                    height: 100%;
                }

                img {
                    width: 44px;
                    height: 44px;
                }
            }
        }

        .yixiu-logo {
            position: absolute;
            cursor: pointer;
            top: 80px;
            right: 100px;

            .logo-1 {
                width: 18px;
                margin-right: 5px;
            }

            .tex {
                font-size: 16px;
                font-weight: 600;
            }

            .logo-2 {
                position: absolute;
                top: -20px;
                right: -78px;
            }
        }
    }

    .tankage {
        background-color: #F4F4F6;
        overflow-y: auto;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background: #aaa;

            &:hover {
                background: #999;
            }
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        .tankage-main {
            min-height: 100%;
            background-color: #ffffff;
            box-shadow: 0px 3px 79px 0px rgba(0, 0, 0, 0.05);
            // border-radius: 8px;
        }
    }

    .footer {
        line-height: 40px;
        text-align: center;
        border-top: 1px solid #F0F2F5;
        position: relative;
        padding-left: 95px;

        img {
            position: absolute;
            height: 40px;
            width: 40px;
            // top: -20px;
            left: calc(50% - 260.93px);
        }

        .file {
            letter-spacing: 16px;
            font-size: 16px;
            font-weight: 600;
        }
    }

    .sidebarfold {
        position: absolute;
        top: 200px;
        background-color: #ffffff;
        height: 64px;
        width: 49px;
        border: 1px solid #f3f3f5;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        cursor: pointer;

        .icon-svg {
            width: 16px;
            height: 16px;
            margin-top: 22px;
            margin-left: 13px;
        }
    }

    //消息
    .message {
        text-align: left;

        /*去掉切换时el-tab-pane底部的蓝色下划线*/
        .el-tabs__active-bar {
            background-color: transparent !important;
        }

        /*去掉tabs底部的下划线*/
        .el-tabs__nav-wrap::after {
            position: static !important;
        }

        .el-badge__content.is-fixed {
            top: 6px;
        }

        .el-drawer.rtl.open {
            border-radius: 16px;
        }

        .el-tabs__item.is-top {
            padding: 0;
            margin-right: 40px;
            font-size: 16px;

            &.is-active {
                background-image: url(@/assets/img/nav-active.png);
                background-repeat: no-repeat;
                background-position: bottom;
                border-bottom: none;
                font-size: 18px;
            }
        }

        .el-drawer__body {
            padding: 20px 0;

            .el-tabs__header {
                padding: 0 40px;
            }
        }

        .off {
            position: absolute;
            top: 28px;
            right: 40px;
            cursor: pointer;

            .icon-svg {
                width: 19px;
                height: 19px;
                vertical-align: middle;
            }
        }
    }

    .ab-content-details {
        // width: 1360px;
        margin: 0 auto;
    }

    .main-content {
        .nav-tab {
            .icon-svg {
                width: 7px;
                height: 7px;
                margin-bottom: 1px;
                margin-left: 4px;
            }
        }

        .el-tabs.actived-manue {
            width: calc(100% - 0px);
        }
    }
}

.organizer-dialog {
    .el-dialog__footer {
        text-align: center;
        padding-top: 0;
    }

    .el-dialog__body {
        padding: 40px;
    }

    .org-item {
        width: 100%;
        height: 60px;
        border-radius: 15px;
        position: relative;
        border: 2px solid #EEEEEE;
        margin-bottom: 20px;
        cursor: pointer;

        &.isActived {
            border: 2px solid #0a7cff;

            .org-tel {
                background-color: #0a7cff;
            }
        }

        .org-tel {
            width: 37px;
            height: 36px;
            background-color: #EEEEEE;
            color: #ffffff;
            border-radius: 15px 0 15px 0;
            position: absolute;
            cursor: pointer;
            top: -2px;
            left: -2px;

            .el-icon {
                margin: 12px;
                width: 12px;
                height: 8px;
                font-weight: bold;
            }
        }

        .org-name {
            font-size: 16px;
        }

        div:last-child {
            text-align: center;
            line-height: 60px;
        }

    }


}

.service-dialog {
    .el-drawer__body {
        margin: auto;
    }

}
.custom-message{
    .el-notification__title{
        font-size: 16px;
    }
    .notify-text{
        font-size: 14px;
    }
}
.notify-button {
    margin-top: 30px;
    width: 100%;
    text-align: center !important;
    button{
        height: 45px;
        line-height: 1;
        font-size: 14px;
        border-radius: 22px !important;
    }
    button:first-child {
        border-color: #F0F2F5;
        background-color: #ffffff;
        border-radius: 8px;
    }

    button:last-child {
        margin-left: 5px;
        background-color: #01458e;
        color: #ffffff;
        border-color: #01458e;
        border-radius: 8px
    }
}

.kefu-body{
    text-align: center;
    padding: 100px 0;
    img{
        width: 250px;
        margin: 0 auto;
    }
    .left{
        float: left;
    }
    .right{
        float: right;
    }
    p{
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-svg{
            width: 20px;
            float: left;
            margin-top: -6px;
        }
    }
}
</style>
