<template>
  <div class="file-upload-btn">
    <el-upload
      :multiple="multiple"
      :show-file-list="false"
      :auto-upload="true"
      :before-upload="beforeAvatarUpload"
      :disabled="disabled"
    >
      <el-button
        class="dialog_upload_btn"
        :type="type"
        :loading="isloading"
        :disabled="disabled"
        plain
      >{{ text }}</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'ab-fileBtn',
  data () {
    return {
      sourceBillId: '',
      isloading: false
    }
  },
  model: {
    prop: 'fileData'
  },
  props: {
    fileData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isJpg: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: '上传附件'
    },
    limitSize: {
      type: Number,
      default: 50
    },
    type: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'el-icon-button_upload'
    },
    url: {
      type: String,
      default: '/oms/billAttach/uploadFile'
    },
    mustId: {
      type: Boolean,
      default: true
    },
    fileKey: {
      type: String,
      default: 'file'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    beforeAvatarUpload (file) {
      const isLtSize = file.size / 1024 / 1024 / this.limitSize < 1
      if (!isLtSize) {
        this.$message({
          type: 'error',
          message: file.name + '不能超过50MB!',
          offset: 380,
          grouping: true,
        })
        return false
      }
      if(this.isJpg) {
      let types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(file.type);
        if (!isImage) {
          this.$message({
              type: 'error',
              message: '上传图片只能是 JPG、JPEG、PNG 格式!',
              offset: 380,
              grouping: true,
          })
          return false
        }
      }
      if (!this.fileData.sourceBillId && this.mustId) {
        this.getTempId(file)
      } else {
        this.sendFile(file)
      }

      return false
    },
    sendFile (file, coreBillIds) {
      this.isloading = true
      let fd = new FormData()
      let getTime = new Date().getTime()
      fd.append('attachType', this.fileData.attachType || '010')
      fd.append('coreBillNumbers', this.fileData.coreBillNumbers || '')
      fd.append('coreBillIds', this.fileData.coreBillIds || '')
      fd.append('sourceBillId', this.fileData.sourceBillId || '')
      fd.append('sourceBillNumber', this.fileData.sourceBillNumber || '')
      fd.append('sourceBillType', this.fileData.sourceBillType || '')
      fd.append(this.fileKey, file)
      fd.append('v', getTime)
      this.upload(fd).then(res => {
        if (res.data && res.data.code === 'C0000') {
          this.$message({
            message: '上传成功',
            type: 'success',
            offset: 380,
            grouping: true,
          })
          this.$emit('change', res.data.result)
        }
      }).finally(e => {
        this.isloading = false
      })
    },
    upload (data) {
      return this.$http({
        method: 'post',
        url:this.$adornUrl(this.url),
        timeout: 20000 * 60,
        data: data,
        headers: {
          'Content-Type': 'multipart/form-data', // 关键
        },
      })
    },
    getTempId (file) {
      this.$http({
        url: this.$adornUrl('/p/system/genId'),
        method: 'get'
      }).then((res) => {
        if (res.data && res.data.code === 'C0000') {
          if (!this.fileData.sourceBillId) {
            this.$emit('getTempBillId', res.data.result)
          }
          this.fileData.sourceBillId = res.data.result
          this.sendFile(file, this.sourceBillId)
        }
      })
    }
  }
}
</script>
