<template>
    <div>
    <el-dialog
  :title="fileTitle"
  v-model="preview.show"
  :append-to-body="true"
  width="80%"
  :lock-scroll="true"
  top="0vh"
  custom-class="imm-dialog-body"
  :close-on-click-modal="!this.preview.isView"
  :before-close="handleClose">
  <div class="main" :style="{height: screenHeight - 270 + 'px'}" >
    <iframe id="immPreviweFile" v-if="!imgSrc" :src="iframeSrc" frameborder="0" style="height:100%"></iframe>
    <img :src="imgSrc" v-if="imgSrc" class="pic" />
    <div class="number" v-if="list && list.length">{{currentIndex + 1 + ' / ' + list.length}}</div>
    <div :class="{'download': true, 'disDownload': isDownLoad, 'isDownload': !isDownLoad}" @click="download"> <el-icon><Download /></el-icon> </div>
    <div class="switch">
        <div class="left" @click="switchFile('-')" v-if="list.length > currentIndex && currentIndex > 0"> <i class="el-icon-arrow-left"></i> </div>
        <div class="right" @click="switchFile('+')" v-if="currentIndex < list.length - 1"> <i class="el-icon-arrow-right"></i></div>
    </div>
  </div>
    <slot name="desc"></slot>
    <template #footer>
      <span class="dialog-footer" v-if="preview.isFooter">
          <slot name="button"></slot>
      </span>
    </template>
</el-dialog>

  <div class="yx-scale" v-show="imgSrc">
        <i class="el-icon-remove-outline item-scale" @click="checkedImg(1)"></i>
        <div class="scale-text" id="scale_text">{{picSize}}%</div>
        <i class="el-icon-circle-plus-outline item-scale" @click="checkedImg(2)"></i>
    </div>
    </div>
</template>

<script>
export default {
    name: 'ab-attPreview',
    data () {
        return {
            fileTitle: '附件预览',
            iframeSrc: '',
            imgSrc: '',
            screenHeight: '',
            picSize: 100,
            list: [],
            isDownLoad: false,
            currentIndex: 1
        }
    },
    props: {
        preview: {
            type: Object,
            default: {}
        }
    },
    watch: {
        'preview.show': {
            handler (val, oldVal) {
                if (val) {
                    this.getFileList()
                } else {
                    this.iframeSrc = ''
                }
            },
            deep: true
        }
    },
    methods: {
        switchFile(type) {
            this.currentIndex = type == '+' ? this.currentIndex+1 : this.currentIndex-1
            this.preview.fileUrl = this.list[this.currentIndex].fileUrl
            this.initFile()
        },
        download() {
            if(this.isDownLoad) {
                return
            }
            let select = [this.list[this.currentIndex]]
            this.isDownLoad = true;

            this.$post('/oms/billAttach/compress', {
                listData: select
            }).then(res => {
                if (res && res.data.result) {
                    this.$message({
                        type: "success",
                        message: "下载成功！",
                        grouping: true,
                        offset: 380
                    });
                    let eleLink = document.createElement("a");
                    eleLink.download = "";
                    eleLink.style.display = "none";
                    // 字符内容转变成blob地址
                    eleLink.href = res.data.result;
                    // 触发点击
                    document.body.appendChild(eleLink);
                    eleLink.click();
                    // 然后移除
                    document.body.removeChild(eleLink);
                }
                })
                .finally(e => {
                    this.isDownLoad = false;
                }, 200);
        },
        initFile() {
            this.screenHeight = this.preview.height || window.screen.height
            let lastIndex = this.preview.fileUrl.lastIndexOf('/') + 1
            this.fileTitle = this.list[this.currentIndex].disName || this.preview.fileUrl.substring(lastIndex)
            let flag = false
            let imgArry = ['.bmp','.jpg','.png','.tif','.gif']
            imgArry.map(e => {
                if (this.preview.fileUrl.indexOf(e) != -1) {
                    setTimeout(() => {
                        this.imgSrc = this.preview.fileUrl
                    }, 50)
                    flag = true
                }
            })
            setTimeout(() => {
                if (!flag) {
                    this.imgSrc = ''
                    if (this.preview.isView) {
                        this.fileTitle = this.preview.fileTitle
                        this.iframeSrc = this.preview.fileUrl
                    } else {
                        let startIndex = 0
                        let type = 'scp'
                        if (this.preview.fileUrl.indexOf('z-easy.com.cn') != -1) {
                            startIndex = this.preview.fileUrl.indexOf('z-easy.com.cn/') + 14
                            type = 'z-easy'
                        } else {
                            startIndex = this.preview.fileUrl.indexOf('cicsc.com/') + 10
                        }
                        this.iframeSrc = 'https://scp-imm.cicsc.com/?file=' + this.preview.fileUrl.substring(startIndex) + '&v=' + new Date().getTime() + '&type=' + type
                    }
                }
            }, 50)
        },
        getFileList () {
            this.isloading = true
            let url = this.preview.url || '/oms/billAttach/queryBySourceData'
            this.$post(url, { sourceBillType: this.preview.sourceBillType, sourceBillId: this.preview.sourceBillId }).then((res) => {
                if (res.data.code == 'C0000') {
                    if (this.preview.sourceBillType == 'PaymentApp' && this.preview.attachType == '020') {
                        this.list = res.data.result[this.preview.attachType] ? [res.data.result[this.preview.attachType][0]] : []
                    } else {
                        this.list = res.data.result[this.preview.attachType] || []
                    }
                    if(this.list && this.list.length > 0) {
                        this.preview.fileUrl = this.list[0].fileUrl
                        this.currentIndex = 0
                        this.initFile()
                    }
                }
            }).finally(e => {
                setTimeout(() => {
                this.isloading = false
                }, 200)
            })
        },
        handleClose () {
            this.imgSrc = ''
            this.$emit('handleClose')
            this.preview.show = false
        },
        checkedImg (str) {
            let image=document.getElementsByClassName("pic")[0]
            if (str == 2) {
                image.style.height=image.height*1.1+'px'
                image.style.width=image.width*1.1+'px'
                this.picSize += 10
            } else {
                image.style.height=image.height/1.1+'px';
		        image.style.width=image.width/1.1+'px';
                this.picSize -= 10
            }

        }
    }
}
</script>

<style lang="scss">
.imm-dialog-body {
    position: relative;
    overflow-x: auto !important;
    .el-dialog__body{
        padding: 5px
    }
    .number{
        position: absolute;
        top: 18px;
        z-index: 9999;
        font-size: 20px;
        left: 50%;
        margin-left: -15px;
    }
    .download{
        position: absolute;
        top: 18px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        right: 140px;
        font-size: 22px;
    }
    .isDownload{
        cursor: pointer;
        &:hover{
            color: #0076f6;
        }
    }
    .disDownload{
        cursor: not-allowed;
    }
    .switch{
        font-size: 20px;
        .left{
            position: absolute;
            left: 20px;
            top: 50%;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            background-color: #fff;
            text-align: center;
            line-height: 30px;
            &:hover{
                background-color: #ccc;
                color: #fff;
            }
        }
        .left,
        .right{
            position: absolute;
            top: 50%;
            cursor: pointer;
            width: 30px;
            height: 30px;
            border-radius: 15px;
            background-color: #fff;
            text-align: center;
            line-height: 30px;
            &:hover{
                background-color: #ccc;
                color: #fff;
            }
        }
        .left{
            left: 20px;
        }
        .right{
            right: 20px;
        }
    }
    #immPreviweFile {
        width: 100%;
        height: 100%;
    }
    .file-preview-desc {
      text-align: center;
      line-height: 30px;
      .el-checkbox__label {
        font-size: 14px;
      }
    }
}
    .yx-scale {
        position: fixed;
        bottom: 15%;
        right: 15%;
        z-index: 99999;
        .item-scale {
            display: inline-block;
            font-size: 22px;
            cursor: pointer;
        }
        .scale-text {
            display: inline-block;
            font-size: 16px;
        }
    }
</style>
